.help{
  padding: 8px 70px 93px 70px;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 0 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 8px 30px 48px 30px;
  }
  &__top-main{
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {

    }
  }
  &__title-main{
    max-width: 1045px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    padding-bottom: 31px;
    margin: 0 auto;
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      width: 100%;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 20px;
      margin-left: 16px;
      padding-bottom: 24px;
    }
  }
  &__collapse{
    margin-top: 50px;
  }
  &__collapse.ant-collapse {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0;
    max-width: 1045px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    background-color: white;
    border-bottom: 0;
    border-radius: 0;
    border: none;
    @media screen and (min-width: 0) and (max-width: 767px) {
      font-size: 18px;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      width: 100%;
    }
  }
  &__collapse.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    border: none;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 40px 0 50px 0;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
    @media screen and (min-width: 0) and (max-width: 767px) {
      padding: 20px 0;
    }
  }
  &__collapse.ant-collapse .ant-collapse-content {
    color: rgba(0, 0, 0, 0.88);
    background-color: #ffffff;
    border-top: none;
  }
  &__collapse.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-mzwlov).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 0;
  }

  &__text-small{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    margin-bottom: 40px;
    @media screen and (min-width: 0) and (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 25px;
    }
  }
  &__help-form{
    width: 100%;
    margin: 48px auto 0;
    max-width: 1045px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 100%;
    }
  }
}

@import "src/config/theme/core";
.card{
  width: 100%;
  //cursor: pointer;
  &__top{
    display: flex;
    padding: 18px 18px 77.463px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 35px;
    border-radius: 20px;
    border: 1px solid rgba(115, 100, 255, 0.10);
    background: var(--white, #FFF);
    cursor: pointer;
    @include for-tablet-small{
      gap: 0;
      padding: 18px;
    } @include for-phone-big{
      gap: 10px;
      padding: 18px;
    } @include for-phone-only{
      gap: 10px;
      padding: 18px;
    }
    //@include for-b
    //min-height: 344px;
    //max-height: 450px;
    //height: 345px;
    //max-height: 344px;
  }
  &__img{
    width: 100%;
    height: 190px;
    object-fit: contain;
    @media screen and (min-width: 500px) and (max-width: 900px) {
      height: 140px;
    }
    @media screen and (min-width: 0) and (max-width: 500px) {
      height: 100px;
    }
    //@include
    //height: 90%;
    //max-height: 250px;
    //height: 100%;
  }
  &__bottom{
    margin-top: 14px;
    cursor: pointer;
  }
  &__name{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    @include for-phone-big{
      font-size: 14px;
    }@include for-phone-only{
      font-size: 14px;
    }
  }
  &__price{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @include for-phone-big{
      font-size: 14px;
    }
    @include for-phone-only{
      font-size: 14px;
    }
  }
  &__like{
    stroke: #7364FF;
    fill: none;
    @media  screen and (max-width: 500px){
      width: 16px;
      height: 16px;
    }
  }
  &__like.active{
    stroke: #7364FF;
    fill: #7364FF;
  }
  &__like:hover{
    stroke: #3B3DC6;
  }
  &__like.ant-btn.ant-btn-icon-only{
    width: fit-content;
  }
}
.card:hover{
  .card__top{
    box-shadow: 0 2px 15px 0 rgba(115, 100, 255, 0.15);
  }
}

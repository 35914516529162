@import "src/config/theme/core";
.search-header{
  position: absolute;
  top: 98px;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  padding: 40px 67px 100px 67px;
  @media screen and (min-width: 768px) and (max-width: 1024px){
    padding: 40px 30px 30px;
  }
  @media screen and (min-width: 20px) and (max-width: 768px){
    padding: 24px 17px 120px
  }

  &__popular-items{
    @media screen and (min-width: 20px) and (max-width: 768px){
      margin-bottom: 32px;
    }
  }

  &__p-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    margin-bottom: 24px;
  }
  &__p-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 14px;
    cursor: pointer;
  }
  &__icon-clean{
    cursor: pointer;
  }
  &__link-btn.button-v2.ant-btn-text {
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  &__link-btn.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--purple, #7364FF);
    background: none;
  }
  &__input{
    width: 74.7%;
    align-self: flex-end;
    padding: 16px 17px;
    border-radius: 320px;
    border: none;
    background: var(--gray-background, #F6F6F6);
    margin-bottom: 40px;

    @media screen and (min-width: 0) and (max-width: 768px){
      width: 100%;
      border-radius: 12px;
      margin-bottom: 20px;
    }
  }
  &__input.ant-input-outlined:hover {
    border: none;
    background-color: #F6F6F6;
  }
  &__input.ant-input:focus, .ant-input:hover {
    border: none;
    background: #F6F6F6 !important;
    box-shadow: none;
  }
  &__input.ant-input-outlined:focus-within {
    border: none;
    box-shadow: none;
    outline: 0;
    background-color: #F6F6F6;
  }
  &__input.ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 16px;
  }
  &__visible{
    z-index: 15000;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__content {
    display: grid;
    grid-template-columns: 25.3% auto;
    width: 100%;
    @media screen and (min-width: 0) and (max-width: 768px){
      display: flex;
      flex-direction: column;
    }
  }
  &__result{
    position: relative;
    @media  screen and (min-width: 0) and (max-width: 768px){
      display: none;
    }
  }
  &__result-mobile{
    display: none;

    @media screen and (min-width: 0) and (max-width: 768px){
      display: block;
    }
  }
  &__result-box{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Два столбца */
    row-gap: 32px;
    column-gap: 18px;
    @media screen and (min-width: 0) and (max-width: 650px){
      padding-bottom: 70px;
    }
  }
  &__slider{
    position: absolute;
    left: 0;
    width: 100%;
  }
  .slick-list{
    overflow: hidden;
  }
  .slick-track{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .slick-next {
    position: absolute;
    top: -33px;
    left: 40px;
    transform: scaleX(-1);
    stroke: #CBC6FF;
    cursor: pointer;
  }
  .slick-prev{
    position: absolute;
    top: -33px;
    stroke: #CBC6FF;
    cursor: pointer;
  }
  .slick-next:hover, .slick-prev:hover{
    stroke: #7364FF;
  }
  &__no-result{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__search-icon.black{
    stroke: #0F0F11;
  }
  &__search-icon.gray{
    stroke: #919198;
  }
  &__popular-icon{
    stroke: #0F0F11;
  }
}

.need-help{
  width: 100%;
  display: flex;
  padding: 40px 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: var(--black, #0F0F11);
  margin-top: 32px;
  @media screen and (min-width: 0) and (max-width: 576px) {
    margin-right: -16px;
    margin-left: -16px;
    width: calc(100% + 32px);
    border-radius: 0;
    padding: 32px 16px 32px 16px;
  }
  &__top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    width: 100%;
    margin-bottom: 8px;
  }
  .ant-form-item, .ant-form-item-control {
    width: 100%;
    height: 60px;
  }
  &__title{
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    margin-bottom: 8px;
  }
  &__subtitle{
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  &__img{
    white-space: nowrap;
    margin-right: -29px;
    @media screen and (min-width: 0) and (max-width: 1300px) {
      display: none;
    }
  }
  &__input.ant-input{
    color: #757575;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: transparent;
    border-bottom: 1px solid #757575;
    border-radius: 0;
    padding: 20px 0;
    margin-bottom: 8px;
  }
  &__input.ant-input::placeholder{
    color: #757575;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__input.ant-input:focus, .ant-input:hover{
    background: none !important;
  }
  &__btn.ant-btn{
    width: 152px;
    margin-top: 32px;
  }
}

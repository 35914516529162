.orders{
  display: flex;
  gap: 22px;
  padding: 8px 67px 82px 67px;
  height: fit-content;
  min-height: 70vh;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 30px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 4px 30px 50px 30px;
  }
  @media screen and (min-width: 0) and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }
  &__menu{
    width: 331px;
    @media screen and (min-width: 650px) and (max-width: 1300px) {
      width: 100%;
    }
    @media screen and (min-width: 0) and (max-width: 650px){
      display: none;
    }
  }
  &__content{
    flex-grow: 1;
  }
  &__top-tab{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
    border-bottom: 1px solid #DFDFDF;
    @media screen and (min-width: 0) and (max-width: 865px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
  }
  &__tabs{
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (min-width: 0) and (max-width: 865px) {
      width: 100%;
    }
  }
  &__tab-0,&__tab-1,&__tab-2,{
    color: #101010;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    width: 192px;
    padding: 16px 32px 14px 32px;
    justify-content: center;
    align-items: center;
    background: #fff;
    cursor: pointer;
    //border-radius: 8px;
    //border: 1px solid var(--black, #0F0F11);
    //gap: 10px;
    //576
    @media screen and (min-width: 0) and (max-width: 540px) {
      //width: 100%;
      padding: 11px 12px 9px 12px;
      font-size: 12px;
    }
  }
  &__tab-0{
    border-radius: 8px 0 0 8px;
    border: 1px solid var(--black, #0F0F11);
    border-right: none;
  }
  &__tab-1{
    border-top: 1px solid #0F0F11;
    border-bottom: 1px solid #0F0F11;
    border-right: 1px solid #E8E8E8;
    border-left: 1px solid #E8E8E8;
  }
  &__tab-2{
    border-radius: 0 8px 8px 0;
    border: 1px solid var(--black, #0F0F11);
    border-left: none;
  }
  &__tab-0.active,&__tab-1.active,&__tab-2.active,{
    color: #FFFFFF;
    background: #101010;
  }
  &__not{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
    @media screen and (min-width: 0) and (max-width: 540px) {
      font-size: 14px;
    }
  }
  &__loading-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60vh;
  }
}

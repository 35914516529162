.info{
  padding: 8px 70px 93px 70px;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 72px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 8px 30px 93px 30px;
  }
  &__top{
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {

    }
  }
  &__title{
    max-width: 690px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    padding-bottom: 31px;
    margin: 0 auto;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 20px;
      margin-left: 16px;
      padding-bottom: 24px;
    }
  }
  &__content{
    max-width: 690px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
  }
  &__subtitle{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 550;
    line-height: 120%; /* 26.4px */
    margin-top: 47px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 20px;
      margin-top: 24px;
    }
  }
  &__subtitle-mt{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 550;
    line-height: 120%; /* 26.4px */
    margin-top: 27px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 20px;
    }
  }
  &__text{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 16px;
    }
  }
  &__text-small{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  &__img{
    width: 100%;
  }
  &__br{
    height: 20px;
  }
  &__color-block{
    width: 100%;
    max-width: 690px;
    height: 322px;
    border-radius: 20px;
    background: var(--purple, #7364FF);
  }
  &__subtitle.second{
    margin-top: 27px;
  }
  &__li {
    padding-left: 30px;
    text-indent: -1.3em;
  }
  &__li:not(:last-child) {
    margin-bottom: 15px;
  }
  &__back{
    color: #303030;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    cursor: pointer;
    align-items: center;
    display: flex;
    gap: 10px;
  }
  &__arrow{
    stroke: #303030;
  }
}

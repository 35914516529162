.login{
  .ant-modal-content{
    padding: 45px 55px;
    @media screen and (min-width: 0) and (max-width: 400px) {
      padding: 20px 25px;
    }
  }
  .ant-modal-body{
    margin-top: 35px;
  }
  &__btn{
    border-radius: 12px !important;
  }
  &__password{
    margin: 76px 0 67px;
  }

  &__registration, &__forgot{
    justify-content: left !important;
  }
  &__modal-btn{
    margin-top: 35px;
  }
  &__btn-tg{
    margin: 12px 0;
  }
}

.contact{
  padding: 8px 67px 133px 67px;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 0 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 8px 30px 78px 30px;
  }
  &__top{
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    padding-bottom: 31px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 20px;
      padding-bottom: 22px;
    }
  }
  &__form-box{
    width: 690px;
    margin-left: 30px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 100%;
      margin-left: 0;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__icon{
    fill: #C2C2C2;
  }
  &__icon:hover{
    fill: #6e6e6e;
  }
  &__box{
    margin-top: 69px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 77px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      gap: 30px;
      margin-top: 24px;
      flex-direction: column;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      margin-top: 47px;
      flex-direction: column;
      gap: 30px;
    }
  }
  &__info-contact{
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 100%;
      justify-content: space-between;
      flex-direction: column-reverse;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      width: 100%;
      justify-content: space-between;
    }
  }
  &__info{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    margin-right: 165px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 20px;
      margin-right: 0;
    }
  }
  &__email{
    margin-top: 18px;
    margin-bottom: 48px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-bottom: 28px;
    }
  }
  &__social-media{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 0) and (max-width: 576px) {
      justify-content: start;
      flex-direction: row;
      gap: 15px;
      margin-bottom: 23px;
    }
  }
  &__line{
    width: 1px;
    height: 156px;
    background-color: #DCDCDC;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 100%;
      height: 1px;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      width: 100%;
      height: 1px;
    }
  }
  &__text{
    width: 490px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 16px;
      width: 100%;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      width: 100%;
    }
  }
  &__br{
    margin-top: 14px;
  }
  &__photo{
    width: 100%;
    margin-top: 66px;
    margin-bottom: 56px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-top: 32px;
      margin-bottom: 16px;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      margin-top: 47px;
      margin-bottom: 93px;
    }
  }
  &__f-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 52.8px */
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 28px;
    }
  }
  &__f-subtitle{
    color: var(--black, #0F0F11);
    font-family: "Montserrat",sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 450;
    line-height: 130%; /* 36.4px */
    margin-top: 21px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 18px;
      margin-top: 12px;
    }
  }
  &__connect{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 0) and (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
    }
  }
}

@import "src/config/theme/core";
.footer{
  display: flex;
  width: 100%;
  padding: 80px 67px;
  flex-direction: column;
  gap: 120px;
  background: var(--black, #0F0F11);
  @include for-tablet-medium{
    gap: 93px;
    padding: 80px 30px;
  }
  @include for-tablet-small{
    gap: 93px;
    padding: 80px 30px;
  }
  @include for-phone-big{
    gap: 50px;
    padding: 64px 16px;
  }
  @include for-phone-only{
    gap: 50px;
    padding: 64px 16px;
  }
  @media screen and (min-width: 0) and (max-width: 650px) {
    padding-bottom: 90px;
  }

  &__main{
    display: grid;

    grid-template-columns: repeat(3, auto);
    gap: 20px;

    @media screen and (min-width: 0) and (max-width: 700px) {
      display: flex;
      flex-direction: column;
      gap: 53px;
    }
  }

  &__contacts, &__info, &__sections{
    min-width: 101px;
    max-width: 335px;
  }
  &__list-contact{
    display: flex;
    flex-direction: column;

  }
  &__title{
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 72px;
    @media screen and (min-width: 0) and (max-width: 700px) {
      margin-bottom: 32px;
    }
  }
  &__c-item{
    margin-bottom: 18px;
  }
  &__c-item:hover{
    color: #FFFFFF;
  }
  &__item, &__mt, &__c-item{
    width: fit-content;
    color: var(--white-50, rgba(255, 255, 255, 0.50));
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  &__item:hover{
    color: var(--white, #FFF);
  }
  &__mt{
    margin-top: 48px;
    cursor: auto;
  }
  &__mt:hover{
    color: #FFFFFF;
  }
  &__list{
    display: flex;
    flex-direction: column;
  }
  &__list > &__item:not(:last-child) {
    margin-bottom: 28px;
    @media screen and (min-width: 0) and (max-width: 700px) {
      margin-bottom: 20px;
    }
  }

  &__social-icons{
    display: flex;
    gap: 15px;
    //margin-left: 15px;
  }
  &__rules, &__icons{
    display: flex;
    justify-content: space-between;
  }
  &__icons{
    @media screen and (min-width: 0) and (max-width: 735px){
      flex-direction: column-reverse;
      gap: 29px;
    }
  }
  &__rules{
    @include for-tablet-small{
      flex-direction: column;
      gap: 16px;
    }@include for-phone-big{
      flex-direction: column;
      gap: 16px;
    }@include for-phone-only{
      flex-direction: column;
      gap: 16px;
    }
  }
  &__left{
    color: var(--white-30, rgba(255, 255, 255, 0.30));
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__right > a{
    color: var(--white-30, rgba(255, 255, 255, 0.30));
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-left: 32px;
    @media screen and (max-width: 1000px){
      margin-left: 0;
    }
  }
  &__right:nth-child(1){
    margin-right: 32px;

  }
  &__right{
    @media screen and (max-width: 1000px){
      //margin-right: 0;
      //border: 1px solid red;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  &__company-name{
    margin-right: 32px;
  }
  &__icon{
    fill: #FFFFFF;
    fill-opacity: 0.3;
    cursor: pointer;
  }
  &__icon:hover{
    fill-opacity: 0.6;
  }
}

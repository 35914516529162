.catalog{
  border: 1px solid red;
  min-height: 80vh;
  //&__top{
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //  justify-content: space-between;
  //  margin-bottom: 32px;
  //}
  //&__right{
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //  justify-content: center;
  //}
  //&__category-name{
  //  color: var(--black, #0F0F11);
  //  font-family: "Montserrat", sans-serif;
  //  font-size: 28px;
  //  font-style: normal;
  //  font-weight: 600;
  //  line-height: normal;
  //  margin-right: 20px;
  //}
  &__count{
    color: #B0B0B0;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 5px;
  }
  &__items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 64px;
    column-gap: 25px;
    @media screen and (min-width: 900px) and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__load-more{
    width: 100%;
    display: flex;
    padding: 20px 16px 18px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #F6F6F6;
    color: #999;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 32px;
  }
  &__load-more.disabled{
    display: none;
  }

  &__nothing-found{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@import "src/config/theme/core";
.search{
  width: 100%;
  height: fit-content;
  padding: 60px 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 64px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 48px 30px;
  }
  //@include for-tablet-small {
  //  padding: 30px 48px;
  //}
  //@include for-phone-big{
  //  padding: 10px 16px 64px;
  //}
  //@include for-phone-only{
  //  padding: 5px 16px 64px;
  //}
  &__content{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    @include for-tablet{
      width: 100%;
      justify-content: space-around;
    }
    @include for-tablet-medium {
      width: 100%;
      justify-content: space-between;
    }
    @include for-phone-big{
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
     @include for-phone-only{
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

  }
  &__title{
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
    @include for-tablet-medium {
      font-size: 24px;
    }
    @include for-tablet-small{
      font-size: 24px;
    }
    @include for-phone-big{
      font-size: 24px;
    }
    @include for-phone-only{
      font-size: 24px;
    }

  }
  &__subtitle{
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @include for-tablet-small{
      font-size: 14px;
    }
    @include for-phone-big{
      font-size: 14px;
    }
    @include for-phone-only{
      font-size: 14px;
    }
  }
  &__search-input{
    display: flex;
    width: 690px;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
    @include for-tablet {
      width: 471px;
    }
    @include for-tablet-medium {
      width: 400px;
    }
    @include for-tablet-small{
      width: 380px;
    }
    @include for-phone-big{
      width: 100%;
    }
    @include for-phone-only{
      width: 100%;
    }
  }
  &__input.ant-input-affix-wrapper{
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1.5px solid var(--white-30, rgba(255, 255, 255, 0.30));
    color: var(--white, #FFF) !important;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: rgba(255, 255, 255, 0);
    @include for-tablet-small{
      font-size: 14px;
    }
  }
  .ant-input:focus, .ant-input:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #fff !important;
    box-shadow: none;
  }
  .ant-input::placeholder{
    color: #fff;
  }
  &__where{
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  &__sneaker-top{
    display: none;

    @include for-phone-big{
      display: block;
      position: relative;
      left: -42px;
    }
    @include for-phone-only{
      display: block;
      position: relative;
      left: -42px;
    }
  }
  &__sneaker{
    transform: scaleX(-1) rotate(25deg);

    @include for-tablet {
      display: none;
    }
    @include for-tablet-medium{
      display: none;
    }
    @include for-tablet-small{
      display: none;
    }
    @include for-phone-big {
      display: none;
    }
    @include for-phone-only{
      display: none;
    }
  }
  &__input.notFound{
    border: 1px solid #F36060 !important;
  }
  //.notFound{
  //  border: 1px solid #F36060;
  //}
  &__title-notFound{
    color: var(--red, #F36060);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__find{
    cursor: pointer;
  }
  &__input.ant-input-outlined:focus{
    box-shadow: none;
  }
  &__input.ant-input-outlined:focus-within{
    box-shadow: none;
  }
  &__input.typing{
    border: 1px solid white;
  }
  &__bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .ant-spin-dot-item{
    background-color: #FFFFFF !important;
  }
}

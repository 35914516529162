@import "src/config/theme/core";

.product-page {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  padding: 0 67px 120px 67px;
  margin-top: 52px;
  border-bottom: 1px solid #D8D8D8;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 0 16px;
    margin-top: 15px;
    flex-direction: column;
    gap: 39px;
  }
  @media screen and (min-width: 0) and (max-width: 767px) {
    //padding: 0 16px 0 16px;
    gap: 39px;
    flex-direction: column;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 0 30px 78px 30px;
  }

  &__images {
    width: 49%;
    @media screen and (min-width: 0) and (max-width: 767px) {
      width: 100%;
    }
  }

  &__thumbnail-image {
    opacity: 0.4;
  }

  &__thumbnail-image.active {
    opacity: 1;
  }

  &__main-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main-image.ant-image-img {
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 100%;
  }

  &__content {
    width: 49%;
    @media screen and (min-width: 0) and (max-width: 767px) {
      width: 100%;
    }
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__name {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
    @media screen and (min-width: 767px) and (max-width: 900px) {
      font-size: 24px;
    }
    @media screen and (min-width: 0) and (max-width: 767px) {
      font-size: 20px;
    }
  }

  &__price {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    @media screen and (min-width: 767px) and (max-width: 900px) {
      font-size: 24px;
    }
    @media screen and (min-width: 0) and (max-width: 767px) {
      font-size: 20px;
    }

  }

  &__favorite-btn {
    width: fit-content !important;
    stroke: #7364FF;
    fill: none;
  }

  &__favorite-btn.active {
    fill: #7364FF;
  }

  &__favorite-btn:hover {
    stroke: #3B3DC6;
  }

  &__orderList-btn.ant-btn:disabled {
    cursor: not-allowed;
    background-color: #8F8F8F;
  }

  &__sizes-grid {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 32px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-bottom: 24px;
    }
  }

  &__tab-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E1E1E1;
  }

  &__tab {
    color: #0F0F11;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px 24px 8px 24px;
    border-radius: 32px;
    border: 1px solid #C8C8C8;
    cursor: pointer;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 13px;
    }
    //background: var(--black, #0F0F11);
  }

  &__tab.active, &__tab-info.active {
    color: var(--white, #FFF);
    background: var(--black, #0F0F11);
    border: none;
  }

  &__tab-content {
    width: 100%;
    margin-top: 20px;
  }

  &__tab-pane {
    display: none;
  }

  &__tab-pane.active {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 18px;
    column-gap: 18px;
    @media screen and (min-width: 850px) and (max-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (min-width: 767px) and (max-width: 850px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (min-width: 550px) and (max-width: 767px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media screen and (min-width: 0) and (max-width: 550px) {
      grid-template-columns: repeat(3, 1fr);
      //padding: 15.923px 24.769px;
    }
    //flex-direction: row;
    //flex-wrap: wrap;
    //gap: 18px;
  }

  &__tab-item {
    display: flex;
    //width: 121px;
    min-width: 82px;
    padding: 18px 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 12px;
    border: 1px solid #C8C8C8;
    cursor: pointer;
    color: var(--black, #0F0F11);
    @media screen and (min-width: 490px) and (max-width: 1210px) {
      padding: 16px 3px;
    }
    @media screen and (min-width: 0) and (max-width: 550px) {
      padding: 15px 24px;
    }
  }

  &__tab-item.not-available {
    border: none;
    cursor: default;
  }

  &__tab-item.choose {
    color: #fff;
    background: var(--black, #0F0F11);
  }

  &__tab-item-size {
    //color: var(--black, #0F0F11);
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: none;
    text-transform: uppercase;
    margin-bottom: 6px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__tab-item-price {
    white-space: nowrap;
    color: #A8A8A8;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: none;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
    }
  }

  &__split {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 22px 32px 20px 32px;
    justify-content: space-between;
    border-radius: 24px;
    background: rgba(115, 100, 255, 0.10);
    margin-top: 64px;
    @media screen and (min-width: 0) and (max-width: 1280px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px);
      border-radius: 0;
      margin-top: 39px;
    }
    //margin-bottom: 32px;
  }

  &__split-price {
    color: #110F0F;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 16px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 18px;
    }
  }

  &__split-sub {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__progress-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__progress-bar > div {
    width: 77px;
    height: 3px;
    border-radius: 2px;
    background-color: var(--purple, #C3BFE9);
  }

  &__progress-bar > div:nth-child(1) {
    background-color: #7364FF;
  }

  &__basket-btn.button-v2.ant-btn {
    width: 231px;
    border-radius: 12px;
  }

  &__split-btn.button-v2.ant-btn {
    width: 204px;
    @media screen and (min-width: 0) and (max-width: 1280px) {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 24px;
    border: 1px solid #C3C3C3;
    margin-top: 32px;
    //align-self: stretch;
  }

  &__tab-header-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    border-bottom: 1px solid #C3C3C3;
    overflow-x: scroll;
    scrollbar-width: none;
  }

  &__tab-info {
    font-family: "Montserrat", sans-serif;
    line-height: normal;
    padding: 14px 25px 12px 25px;
    border-radius: 12px;
    border: 1px solid #C8C8C8;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 28px;
  }

  &__tab-info.active {
    color: var(--white, #FFF);
    background: var(--black, #0F0F11);
    border: none;
  }

  &__tab-pane-info {
    display: none;
  }

  &__tab-pane-info.active {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    display: flex;
  }

  &__thumbnail.ant-image {
    height: 90px !important;
    width: 100%;
  }

  &__thumbnail-image {
    height: 90px !important;
    cursor: pointer;
    object-fit: contain;
  }

  .slick-slide, .slick-active {
    object-fit: contain;
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 32px;
    padding: 0 67px;
    gap: 10px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-top: 15px;
      padding: 0 16px;
      gap: 4px;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      padding: 0 30px;
    }
    //@include for-phone-only {
    //  display: none;
    //}
  }

  &__breadcrumbs-last, &__breadcrumbs-item, &__b-delimiter {
    color: #919191;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
    }
  }

  &__b-delimiter {
    color: #E1E1E1;
  }

  &__breadcrumbs-item {
    cursor: pointer;
  }

  &__breadcrumbs-item:hover {
    color: #303030;
  }

  &__loading-box {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__test-prop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    background-color: #FFFFFF;
    border: 1px solid #0F0F11;
    padding: 25px;
    border-radius: 30px;

    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    //text-transform: uppercase;
  }

  :where(.css-dev-only-do-not-override-mzwlov).ant-image .ant-image-mask .ant-image-mask-info {
    display: none;
  }

  :where(.css-dev-only-do-not-override-mzwlov).ant-image .ant-image-mask:hover {
    opacity: 0;
  }
  :where(.css-mzwlov).ant-image .ant-image-mask:hover {
    opacity: 0;
  }
  &__clue{
    text-align: right;
    color: var(--purple, #7364FF);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px;
    cursor: pointer;
  }
  &__clue:hover{
    color: rgba(115, 100, 255, 0.8);
  }
  &__not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40vh;
    width: 100%;
    gap: 15px;

    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px;
  }
  &__not-found-btn.ant-btn{
    width: 220px;
  }
}
.ant-spin-dot-item{
  background-color:#303030 !important;
}

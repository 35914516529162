.popUp{
  width: 100%;
  z-index: 999;
  background-color: #fff;
  padding: 40px 0;
  @media  screen and (min-width: 0) and (max-width: 650px){
    padding: 24px 16px;
  }
  //> ul{
  //  display: flex;
  //  flex-direction: row;
  //  flex-wrap: wrap;
  //  justify-content: space-between;
  //  gap: 20px;
  //  @media  screen and (min-width: 0) and (max-width: 650px){
  //    flex-direction: column;
  //    gap: 20px;
  //  }
  //  > li{
  //    color: var(--black, #0F0F11);
  //    font-family: "Montserrat", sans-serif;
  //    font-size: 14px;
  //    font-style: normal;
  //    font-weight: 500;
  //    line-height: normal;
  //    cursor: pointer;
  //  }
  //}
  &__block{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    flex-wrap: wrap;
    @media  screen and (min-width: 0) and (max-width: 650px){
      grid-template-columns: repeat(2, 1fr);
    }
    //height: auto;
    > p{
      //height: fit-content;
      color: var(--black, #0F0F11);
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      padding-bottom: 10px;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    max-height: 440px;
    //364px
    &.max-h1{
      max-height: 700px;
      @media screen and (min-width: 0) and (max-width: 650px) {
        max-height: fit-content;
      }
    }
    &.max-h2{
      max-height: 364px;
      @media screen and (min-width: 0) and (max-width: 650px) {
        max-height: fit-content;
      }
    }
    @media screen and (min-width: 0) and (max-width: 650px) {
      max-height: fit-content;
    }

    //column-gap: 50px;
    //gap: 20px; /* Adjust as needed */
  }
  &__list-block{
    //flex: 1 1 200px;
    height: fit-content;
    width: fit-content;
  }
  li{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    padding-bottom: 20px;
    cursor: pointer;
    list-style-type: none;
  }
  li:hover{
    color: #8F8F8F;
  }
  &__all{
    color: #8F8F93;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
  }
  &__all:hover{
    color: #0F0F11 !important;
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 15px;
    cursor: pointer;
    @media screen and (min-width: 0) and (max-width: 650px) {
      gap: 0;
      justify-content: space-between;
    }
  }
  h4 > p:hover{
    color: rgba(48, 48, 48, 0.55);
  }
}
.ant-popover {
  width: 100%;
  //border: 1px solid red;
  padding: 0 67px;
  top: 99px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  box-shadow: none !important;
  background-color: #fff;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 0 30px;
  }
}
.ant-popover-inner {
  padding: 0 !important;
  box-shadow: none !important;
}

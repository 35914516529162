@import "src/config/theme/core";
.introduction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 100px 27px 60px 67px;
  overflow: hidden;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 100px 27px 30px 30px;
  }
  @media screen and (min-width: 490px) and (max-width: 768px){
    flex-direction: column;
    padding: 15px 27px 30px 30px;
  }
  @media screen and (min-width: 0) and (max-width: 490px){
    flex-direction: column;
    padding: 15px 16px 30px 16px;
  }
  &__context {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 80px;
    @media screen and (min-width: 0) and (max-width: 768px){
      margin-right: 0;
    }
  }
  &__mobile-top{
    display: none;
    @media screen and (min-width: 0) and (max-width: 768px){
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }
  }
  &__page-mobile {
    display: none;
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 768px){
      display: block;
    }
  }
  &__page {
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 768px){
      display: none;
    }
  }

  &__text-box{
    width: 273px;
    @media screen and (min-width: 0) and (max-width: 768px){
      width: 100%;
    }
  }
  &__text {
    padding-bottom: 45%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    @media screen and (min-width: 0) and (max-width: 768px){
      padding-bottom: 7%;
    }
  }
  &__text--active-0 {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  &__text--active-1 {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  &__text--active-2 {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  &__title {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
  }
  &__sub {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  &__carousel {
    position: relative;
    width: 1045px;
    height: 550px;
    @media screen and (min-width: 768px) and (max-width: 1024px){
      height: 454px;
      width: 637px;
    }
    @media screen and (min-width: 600px) and (max-width: 768px){
      height: 454px;
      width: 800px;
    }
    @media screen and (min-width: 490px) and (max-width: 600px){
      height: 300px;
      width: 800px;
    }
    @media screen and (min-width: 0) and (max-width: 490px){
      height: 188px;
      width: 800px;
    }
  }
  &__block {
    position: relative;
    width: 100%;
    height: 550px;
    display: flex;
    padding: 156px 50px 131px 0;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    border-radius: 20px;
    background: var(--white, #FFF);
    box-shadow: 0 2px 15px 0 rgba(115, 100, 255, 0.10);
    overflow: hidden;
    transition: opacity 2s ease-out, transform 1s ease-out;
    opacity: 1;
    @include for-tablet-medium{
      padding: 108px 33px 120px 0;
    }@include for-tablet-small{
      padding: 108px 33px 120px 0;
    }
    @media screen and (min-width: 600px) and (max-width: 1024px){
      height: 454px;
    }
     @media screen and (min-width: 490px) and (max-width: 600px){
      height: 300px;
    }
    @media screen and (min-width: 0px) and (max-width: 490px){
      height: 188px;
      padding: 44px 0;
    }
  }
  &__block--active {
    z-index: 100;
    opacity: 1;
    border: 2px solid var(--purple, #7364FF);
  }
  &__block--inactive {
    opacity: 0.5;
    transition: opacity 0.4s ease-in-out;
    @media screen and (min-width: 0) and (max-width: 600px){
      opacity: 0;
    }
  }
  &__block--leaving {
    z-index: 50;
    opacity: 0.5;
    transform: translate(-20px, -20px);
    @media screen and (min-width: 0) and (max-width: 600px){
      opacity: 0;
    }
  }
  &__items{
    @media screen and (min-width: 0) and (max-width: 490px){
      width: fit-content;
    }
  }
  &__progress-bar-mobile{
    display: none;
    flex-direction: row;
    gap: 14px;
    @media screen and (min-width: 0) and (max-width: 768px){
      display: flex;
    }
  }
  &__progress-bar {
    display: flex;
    flex-direction: row;
    gap: 14px;
    @media screen and (min-width: 0) and (max-width: 768px){
      display: none;
    }
  }
  &__progress {
    width: 55px;
    height: 3px;
    border-radius: 1px;
    background-color: #F0EFFF;
  }
  &__progress--active {
    background-color: #7364FF;
  }
  &__img {
    width: 431.58px;
    height: 262.344px;
    transform: scaleX(-1);
    cursor: pointer;
    @media screen and (min-width: 768px) and (max-width: 1400px){
      width: 90%;
      height: 90%;
    }
    @media screen and (min-width: 490px) and (max-width: 768px){
      width: 90%;
      height: 90%;
    }
    @media screen and (min-width: 0) and (max-width: 490px){
      width: 60%;
      height: 60%;
    }
  }
  &__slider{
    position: relative;
    left: 5%;
    width: 100%;
    height: fit-content;
  }
  .slick-track{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .slick-next {
    position: absolute;
    bottom: -7%;
    left: 30%;
    transform: scaleX(-1);
    stroke: #CBC6FF;
    cursor: pointer;
    @media screen and (min-width: 576px) and (max-width: 1300px) {
      bottom: -15%;
    }
  }
  .slick-prev{
    position: absolute;
    bottom: -7%;
    left: 25%;
    stroke: #CBC6FF;
    cursor: pointer;
    @media screen and (min-width: 576px) and (max-width: 1300px) {
      bottom: -15%;
    }
  }
  .slick-next:hover, .slick-prev:hover{
    stroke: #7364FF;
  }
  &__link-btn.button-v2.ant-btn{
    width: 207px;
    @media screen and (min-width: 0) and (max-width: 768px){
      display: none;
    }
  }
  &__link-btn-mobile.button-v2.ant-btn{
    display: none;
    @media screen and (min-width: 0) and (max-width: 768px){
      display: block;
    }
  }
}

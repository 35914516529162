.button-v2 {
  &.ant-btn {
    //height: @btn-v2-height;
    height: 49px;
    width: 100%;
    border-radius: 32px;
    padding: 18px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: none;
  //  .media-tablet-only({
  //  //font-weight: @tags-font-weight;
  //});
  //  &:not(.ant-btn-block) {
  //    // если не во всю ширину родителя
  //    //max-width: @btn-v2-max-width;
  //  }

    &.ant-btn-icon-only {
      background: none;
      border: none;
      height: fit-content;
      padding: 0 !important;
      box-shadow: none;
      &:active, &:hover{
        //color: none;
        border: none;
        background: none;
        box-shadow: none;
      }
    }

    &.ant-btn.square-40 {
      width: 40px;
      height: 40px;
    }

    &.ant-btn-circle {
      border-radius: 50%;
    }

    .anticon {
      line-height: 0;
      font-size: 20px;
    }

    &-primary {
      background: var(--black, #0F0F11);
      color: #fff;

      &:hover {
        background: #0F0F11 !important;
        transition: none;
      }
    }
    &-primary-gray{
      @media screen and (min-width: 0) and (max-width: 650px) {
        background-color: #F7F7F7;
        //height: 57px;
        //justify-content: center;
      }
    }
    &-primary-light{
      background: #29A1DD !important;
      border-radius: 12px;
      color: var(--white, #FFF);
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      //background: #29A1DD;
    }
    &-primary-green{
      background: #1DBE37 !important;
      border-radius: 12px;
      color: var(--white, #FFF);
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      //background: #29A1DD;
    }
    &-text{
      padding: 0;
      height: fit-content;
      color: #ACACAC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      //text-align: left;
      //TODO чекнуть что это поменяло (убрала !important)
      &:hover{
        background: none !important;
        color: #ACACAC;
      }
    }

    &-primary-background-ghost {
      background: none;
      //border-color: @btn-v2-color-primary;
      color: #ACACAC;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      //&:hover {
      //  border-color: @el-hover-color;
      //  color: @el-hover-color;
      //}
      //
      //&[disabled] {
      //  background: transparent;
      //  border-color: @el-disabled-color;
      //  color: @el-disabled-color;
      //}
    }

    &-link {
      display: flex;
      padding: 16px 32px;
      align-items: center;
      border-radius: 8px;
      background: var(--purple, #7364FF);

      color: var(--white, #FFF);
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &:hover{
        color: #fff !important;
        background: var(--purple-hover, #3B3DC6) !important;
      }
    }
    //&-text {
    //  color: @primary-color;
    //  background-color: transparent;
    //  padding: 0;
    //  height: auto;
    //  &:hover,
    //  &:focus {
    //    color: @el-hover-color;
    //    background-color: transparent;
    //  }
    //}

    //&__download {
    //  height: initial;
    //  width: initial;
    //  padding: initial;
    //  border: initial;
    //  background: initial;
    //  font-size: 18px;
    //
    //  &:focus {
    //    color: @text-primary;
    //    background: @bg-primary;
    //    border-color: @bg-primary;
    //  }

    //  &:hover {
    //    color: @text-primary;
    //    background: @bg-primary;
    //    border-color: @bg-primary;
    //  }
    //}
  }

  //&--checkbox.ant-checkbox-wrapper {
  //  font-family: @btn-v2-font-family;
  //  font-size: @btn-v2-font-size;
  //  font-style: normal;
  //  font-weight: 400;
  //  line-height: @btn-v2-line-height;
  //  color: @bc-color;
  //
  //  //.ant-checkbox {
  //  //  //&-inner {
  //  //  //  border-radius: 2px;
  //  //  //  border: 1px solid @login-separator-line;
  //  //  //  background-color: @btn-v2-color-secondary;
  //  //  //}
  //  //
  //  //  //&-checked .ant-checkbox-inner {
  //  //  //  background-color: @btn-v2-color-primary;
  //  //  //}
  //  //}
  //}
}

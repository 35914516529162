//@import '../../theme/core.less';
//
//ant-input
//.input-v2{
//  height: 100%;
//}

.ant-input{

  //height: @inp-v2-height;
  //padding: @inp-v2-padding;
  width: 100%;
  //height: 100%;
  padding: 20px 16px;
  border: none;
  border-radius: 8px;
  background: #F8F8F8;

  font-family: 'Montserrat', sans-serif;
  color: #B4B4B4;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}
.input-v2__form-item{
  height: fit-content;
  //border: 1px solid red !important;
}
.ant-input:focus, .ant-input:hover{
  border: none;
  background: #F8F8F8 !important;
  color: #B4B4B4;
  box-shadow: none;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-form-horizontal .ant-form-item-control {
  flex: auto; /* Используйте auto или другие значения для flex, чтобы настроить поведение элементов */
  min-width: 0;
}

//
//.input-v2 {
//  &__form-input {
//    .ant-registration-item-label label {
//      color: red
//    }
//  }
//}
.ant-form-item-label label::before,
.ant-form-item-label label::after,
.ant-input-wrapper input::before,
.ant-input-wrapper input::after {
  content: none !important;
  text-align: left;
}
.ant-form-item{
  width: 100%;
  //border: 1px solid green;
}
.ant-col, .ant-form-item-control {
  width: 100%;
}
//.ant-form-item-control-input{
//  width: 150%;
//  border: 1px solid gold;
//}
.ant-form{
  width: 100% !important;
}
.ant-form-item-label > label{
  color: var(--black, #0F0F11);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}
.ant-form-item-label{
  text-align: left !important;
}
.ant-row, .ant-form-item-row{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-v2__textarea {
  & > textarea {
    padding: 8px 16px 24px;
  }

  & > textarea.ant-input-borderless {
    padding: 0;
    height: fit-content;
    overflow: hidden;
    resize: none;
  }
}
.ant-form-item{
  margin: 0;
}
.input-v2__textarea.ant-input.input-v2 {
  height: 100% !important;
}
//.input-v2__select-pvz.ant-select-selector{
//  background-color: red;
//}
//:where(.css-dev-only-do-not-override-mzwlov).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector{
//  background-color: red;
//}

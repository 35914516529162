.filters{
  width: 100%;
  background-color: #fff;
  @media  screen and (min-width: 0) and (max-width: 767px) {
    padding-right: 20px;
    padding-bottom: 20px;
  }
  &__delete-btn.ant-btn-text{
    display: flex;
    justify-content: left;
    color: #939393;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover{
    color: #939393;
  }
  &__tab-header{
    //display: flex;
    //flex-direction: row;
    //align-items: flex-start;
    //gap: 8px;
    //
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    //gap: 9px;
    padding-bottom: 16px;
    //border-bottom: 1px solid #E1E1E1;
    &.bottom-line{
      border-bottom: 1px solid #E1E1E1;
    }
  }
  &__tab{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    text-align: center;
    color: #0F0F11;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px 24px 8px 24px;
    border-radius: 32px;
    border: 1px solid #C8C8C8;
    cursor: pointer;
  }
  &__tab-content{
    width: 100%;
    margin-top: 7px;
  }
  &__tab-item{
    min-width: 66px;
    max-width: 66px;
    display: flex;
    padding: 10px 24px 8px 24px;
    align-items: center;
    justify-content: center;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #C8C8C8;
    cursor: pointer;
  }
  &__tab-item.choose{
    color: #fff;
    background: var(--black, #0F0F11);
  }
  &__tab.active{
    border: none;
    background-color: #0F0F11;
    color: #FFFFFF;
  }
  &__tab-pane{
    display: none;
  }
  &__tab-pane.active, &__apparel-pane{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    //column-gap: 18px;
    row-gap: 20px;

  }
  &__collapse{
    background: none;
    border: none;
    padding: 0;

  }
  &__collapse.ant-collapse>.ant-collapse-item{
    border-bottom: none;
    padding: 0;
  }
  &__collapse.ant-collapse-content, .ant-collapse-content-active{
    border-top: none;
    padding: 0;
  }
  &__collapse.ant-collapse>.ant-collapse-item >.ant-collapse-header{
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    margin-top: 40px;
  }
  &__collapse.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon{
    //flex-direction: row-reverse;
    padding: 0;
    color: #CDCDCD;
  }
  &__collapse.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-header-text{
    color: #303030;
    font-family: "Montserrat", sans-serif;
    font-size: 17.04px;
    font-style: normal;
    font-weight: 550;
    line-height: 100%; /* 17.04px */
  }
  &__collapse.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 30px 0;
    border-bottom: 2px solid #F3F3F3;
  }
  &__box{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
  }
  &__title-single{
    color: #303030;
    font-family: "Montserrat", sans-serif;
    font-size: 17.04px;
    font-style: normal;
    font-weight: 550;
    line-height: 100%; /* 17.04px */
  }
  &__ellipse{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #303030;
  }
  &__brand-item{
    color: #303030;
    font-family: "Montserrat", sans-serif;
    font-size: 17.04px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 17.04px */
  }
  &__brand-count{
    color: #9A9A9A;
    font-family: "Montserrat", sans-serif;
    font-size: 17.04px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 17.04px */
  }

  &__slider-price.ant-slider .ant-slider-track {
    background: #303030;
    border-radius: 2px;
  }
  &__slider-price.ant-slider-horizontal .ant-slider-track{
    height: 3px;
  }
  &__slider-price.ant-slider-horizontal .ant-slider-rail{
    height: 3px;
  }

  //&__slider-price.ant-slider .ant-slider-handle::before {
  //content: "";
  //position: absolute;
  //inset-inline-start: -5px;
  //inset-block-start: -5px;
  //width: 14px;
  //height: 14px;
  //background-color: #303030;
  //}
  .ant-slider .ant-slider-handle::after {
    content: "";
    position: absolute;
    inset-block-start: -5px;
    inset-inline-start: -7px;
    width: 18px;
    height: 18px;
    background-color: #303030;
    box-shadow: none;
    border-radius: 50%;
    cursor: pointer;
    transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s;
  }
  &__range-box{
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    //padding: 13px 0;
  }
  &__range-from, &__range-to{
    width: 50%;
    height: 100%;
    color: #303030;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    padding-top: 13px;
  }
  &__range-from{
    border-right: 1px solid #E5E5E5;
  }
  &__in-stoke{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }
  &__in-title{
    color: #303030;
    font-family: "Montserrat", sans-serif;
    font-size: 17.04px;
    font-style: normal;
    font-weight: 550;
    line-height: 100%; /* 17.04px */
  }
  &__in-count{
    color: #9A9A9A;
    font-family: "Montserrat", sans-serif;
    font-size: 17.04px;
    font-style: normal;
    font-weight: 550;
    line-height: 100%; /* 17.04px */
    padding-left: 10px;
  }
  &__switch.ant-switch.ant-switch-checked{
    background: #303030;
  }
  &__switch.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
    background: #303030;
  }
  &__switch.ant-switch{
    height: 24px;
  }
  &__switch.ant-switch .ant-switch-handle{
    top: 3px;
  }
  &__switch.ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 21px);
  }
}

.order-product-item{
  display: flex;
  flex-direction: row;
  width: 100%;
  //border-bottom: 1px solid #E6E6E6;
  padding-bottom: 32px;
  margin-top: 32px;
  &__img-container{
    display: flex;
    min-width: 190px;
    max-width: 190px;
    height: 190px;
    padding: 43.672px 11.345px 43.934px 10.207px;
    justify-content: center;
    align-items: center;
    border-radius: 18.149px;
    border: 0.567px solid rgba(115, 100, 255, 0.10);
    background: var(--white, #FFF);
    margin-right: 32px;
    @media screen and (min-width: 0) and (max-width: 750px) {
      margin-right: 10px;
      height: 130px;
      max-width: 130px;
      min-width: 130px;
      padding: 24.985px 5.968px 24.123px 5.375px;
    }
  }
  &__img{
    width: 100%;
  }
  &__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    width: inherit;
  }
  &__info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__name-size{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    gap: 10px;
  }
  &__name{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: uppercase;

    max-width: 330px;
    @media screen and (min-width: 0) and (max-width: 750px) {
      font-size: 14px;
    }
  }
  &__price{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media screen and (min-width: 0) and (max-width: 750px) {
      font-size: 14px;
      margin-left: 5px;
    }
  }
  &__sub-size{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    @media screen and (min-width: 0) and (max-width: 750px) {
      font-size: 14px;
    }
  }
  &__size{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
    @media screen and (min-width: 0) and (max-width: 750px) {
      font-size: 14px;
    }
  }
  &__count-box{
    width: fit-content;
    margin-bottom: 14px;
  }
  &__count{
    display: flex;
    padding: 5px 19px;
    align-items: center;
    border-radius: 8px;
    background: #F8F8F8;

    color: #303030;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    @media screen and (min-width: 0) and (max-width: 750px) {
      font-size: 10px;
    }
  }
}

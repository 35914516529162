.main{
  &__top-search{
    margin: 60px 0;
    @media screen and (min-width: 0) and (max-width: 768px){
      margin: 0;
    }
  }
  &__bottom-search{
    margin-top: 32px;
  }
}

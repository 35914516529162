.place-order{
  display: grid;
  grid-template-columns: 1005px auto;
  flex-direction: row;
  padding: 8px 67px 48px 67px;
  gap: 60px;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 52px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 4px 30px 78px 30px;
  }
  @media  screen and (min-width: 1390px) and (max-width: 1470px) {
    grid-template-columns: 900px auto;
  }
  @media  screen and (min-width: 1330px) and (max-width: 1390px) {
    grid-template-columns: 810px auto;
  }
  @media  screen and (min-width: 0) and (max-width: 1330px) {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @media screen and (min-width: 0) and (max-width: 700px) {
      margin-bottom: 32px;
    }
  }
  &__back{
    color: #898989;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    cursor: pointer;
  }
  &__info-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__info{
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media screen and (min-width: 576px) and (max-width: 700px) {
      flex-direction: column-reverse;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      flex-direction: column-reverse;
      gap: 0;
    }
  }
  &__form{
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (min-width: 0) and (max-width: 880px) {
      flex-direction: column;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      gap: 0;
    }
  }
  &__delivery{
    width: 50%;
    border-radius: 12px;
    border: 1px solid rgba(123, 42, 229, 0.20);
    padding: 32px;
    &--required {
      border: 1px solid red;
    }
    @media screen and (min-width: 576px) and (max-width: 880px) {
      width: 100%;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px);
      border-radius: 0;
      border-right: 0;
      border-left: 0;
      padding: 32px 16px;
    }
  }
  &__user-info{
    width: 50%;
    //383
    border-radius: 12px;
    border: 1px solid rgba(123, 42, 229, 0.20);
    padding: 32px;
    @media screen and (min-width: 576px) and (max-width: 880px) {
      width: 100%;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 100%;
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px);
      border-radius: 0;
      border-right: 0;
      border-left: 0;
      padding: 32px 16px;
    }
  }
  &__payment{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 67px;

    @media  screen and (min-width: 930px) and (max-width: 1250px) {
      width: 50%;
      margin-top: 0;
    }
    @media  screen and (min-width: 700px) and (max-width: 930px) {
      width: 70%;
      margin-top: 0;
    }
    @media screen and (min-width: 0) and (max-width: 700px) {
      width: 100%;
      margin-top: 0;
    }
  }
  &__count-price{
    width: 100%;
    border-radius: 12px;
    padding: 22px 20px;
    background: rgba(115, 100, 255, 0.05);
  }
  &__total, &__benefit, &__points, &__sum{
    //width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__total{
    margin-bottom: 19px;
  }
  &__s-count{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  &__b-title, &__p-title, &__p-count{
    font-weight: 400;
  }
  &__p-title{
    padding-left: 10px;
  }
  &__b-count{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  &__points{
    margin-bottom: 19px;
    //padding-bottom: 19px;
    //border-bottom: 1px solid #D2CDFF;
  }
  &__benefit{
    margin-top: 20px;
    margin-bottom: 13px;
  }
  &__sum{
    padding-top: 20px;
    border-top: 1px solid #D2CDFF;
  }
  &__write-off{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
  &__w-title{
    color: #303030;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
  }
  &__w-count{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    margin-left: 10px;
  }
  &__input-promo-code.ant-input{
    //border-radius: 8px;
    background: #F8F7FF;
    color: #6554FF;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 12px;
  }
  &__input-promo-code.ant-input::placeholder{
    color: #6554FF;
  }
  &__input-promo-code.ant-input:hover{
    //border-radius: 8px;
    background: #F8F7FF !important;
  }
  &__agreement{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  &__a-title{
    color: var(--black, #0F0F11);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &--warning{
      color: red;
    }
  }
  &__link{
    color: var(--purple, #7364FF);
    //font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
  }
  &__pay.ant-btn{
    border-radius: 12px;
  }
  &__split{
    margin-top: 10px;
  }
  &__split.ant-btn{
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--purple, #7364FF);
    background: var(--white, #FFF);
    color: #4D3EDC;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__split.ant-btn-text:hover{
    color: #4D3EDC !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0F0F11 !important;
    border-color: #0F0F11 !important;
  }
  .ant-checkbox-inner:hover {
    border-color: #0F0F11 !important;
    background-color: #0F0F11 !important;
  }

  :where(.css-dev-only-do-not-override-mzwlov).ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: #0F0F11 !important;
    border-color: #0F0F11 !important;
  }
  .ant-checkbox-checked:hover{
    background-color: #0F0F11 !important;
    border-color: #0F0F11 !important;
  }
  &__switch.ant-switch.ant-switch-checked {
    background: #7364FF;
  }
  &__switch.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #7364FF;
  }

  &__d-title-box{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  &__express-d-box{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 28px;
  }
  &__u-title, &__d-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
  }
  &__d-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #FFFFFF;
    margin-bottom: 18px;

    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  &__d-items-0{
    display: flex;
    width: 119px;
    padding: 16px 32px 14px 32px;
    justify-content: center;
    align-items: center;
    border: 1px solid #7364FF;
    border-radius: 8px 0 0 8px;
    @media  screen and (min-width: 780px) and (max-width: 865px) {
      width: 100px;
    }
    @media  screen and (min-width: 700px) and (max-width: 780px) {
      width: 85px;
      font-size: 12px;
    }
  }
  &__d-items-1{
    display: flex;
    width: 119px;
    padding: 16px 32px 14px 32px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #7364FF;
    border-bottom: 1px solid #7364FF;
    @media  screen and (min-width: 780px) and (max-width: 865px) {
      width: 100px;
    }
    @media  screen and (min-width: 700px) and (max-width: 780px) {
      width: 85px;
      font-size: 12px;
    }
  }
  &__d-items-2{
    display: flex;
    width: 119px;
    padding: 16px 32px 14px 32px;
    justify-content: center;
    align-items: center;
    border: 1px solid #7364FF;
    border-radius: 0 8px 8px 0;
    @media  screen and (min-width: 780px) and (max-width: 865px) {
      width: 100px;
    }
    @media  screen and (min-width: 700px) and (max-width: 780px) {
      width: 85px;
      font-size: 12px;
    }
  }

  .d-active{
    background-color: #7364FF;
    color: #FFFFFF;
  }
  &__d-sub{
    font-weight: 600;
  }
  &__warning{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-top: 4%;
  }
  &__input-delivery{
    margin-bottom: 17.5px;
  }
  &__input-delivery.ant-select-single.ant-select-sm{
    height: 55px;
    border-radius: 8px;
    background: #F8F8F8;
  }
  &__input-delivery.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border-radius: 8px;
    border-color: transparent;
    background: #F8F8F8;
  }
  &__input-delivery.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
    border-color: transparent;
    border-radius: 8px;
    background: #F8F8F8;
  }
  .ant-form-item{
    height: 107px;
  }

  &__user-input.ant-input{
    color: #0F0F11;
  }
  //&__user-input.ant-input::placeholder{
  //  color: #0F0F11;
  //}
  &__input-delivery.ant-input{
    color: #0F0F11;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-input-outlined.ant-input-disabled, :where(.css-dev-only-do-not-override-mzwlov).ant-input-outlined[disabled] {
    background-color: #F8F8F8;
    border-color: #F8F8F8;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-select-selection-item{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
  &__product-count-box{
    display: flex;
    align-items: flex-end;
    gap: 12px;
    flex-direction: row;
    > h3{
      color: var(--black, #0F0F11);
      font-family: "Montserrat", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    > p {
      color: #8F8F8F;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 2px;
    }
  }
  &__input-pvz{
    margin-top: 2px;
  }
  &__input-pvz.ant-select-single.ant-select-sm{
    height: 54px;
    padding: 0 9px;
    border-radius: 8px;
    background: #F8F8F8;
  }
  &__input-pvz.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border-radius: 8px;
    border-color: transparent;
    background: #F8F8F8;
  }
  &__input-pvz.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
    border-color: transparent;
    border-radius: 8px;
    background: #F8F8F8;
  }
  .ant-select-selection-search-input {
    height: 100%;
  }
  :where(.css-mzwlov).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
  .ant-select-selection-placeholder{
    color: #b9b9b9;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: none;
    box-shadow: none;
    outline: 0;
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: none;
    box-shadow: none;
    //outline: 0;
  }
  &__spin{
    &--isClicked{
      &__spin > .ant-spin-dot,.ant-spin-dot-spin > .ant-spin-dot-item{
        background-color: #3b3dc6 !important;
      }
    }
    &--isSingle{
      &__spin > .ant-spin-dot,.ant-spin-dot-spin > .ant-spin-dot-item{
        background-color: #FFFFFF !important;
      }
    }
  }
  .ant-spin-dot-item{
    background-color: #FFFFFF !important;
  }

  &__inputs{
    width: 100%;
    color: #0F0F11;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #F8F8F8;

    display: flex;
    height: 55px;
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
    margin-top: 7px;
    border-radius: 8px;

    &.warning{
      border: 0.5px solid #ff4d4f;
      background-color: #fff;
    }
  }
  &__inputs::placeholder{
    color: #b9b9b9;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__label{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__chooseTab{
    text-align: left;
    margin-top: 20px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &--warning{
      color: red;
    }
  }
  &__reverse{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    //gap: 10px;
  }
  &__btn-block{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__input-select{
    margin-top: 2px;
  }
  &__input-select.ant-select-single.ant-select-sm{
    height: 54px;
    padding: 0 9px;
    border-radius: 8px;
    background: #F8F8F8;
  }
  &__input-select.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border-radius: 8px;
    border-color: transparent;
    background: #F8F8F8;
  }
  &__input-select.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
    border-color: transparent;
    border-radius: 8px;
    background: #F8F8F8;
  }
  .ant-select-selection-search-input {
    height: 100%;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: #F8F8F8;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    border-radius: 8px;
    height: 55px;
    padding: 0 16px;
    //max-width: 205px; /* Установите нужное значение */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single {
    font-size: 14px;
    height: 55px;
  }
  :where(.css-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: #F8F8F8;
    border-radius: 8px;
  }
  :where(.css-mzwlov).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    //height: 100%;
    padding: 0 16px;
    height: 55px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: transparent;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: transparent;
  }
  .ant-select-show-search:where(.css-dev-only-do-not-override-mzwlov).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    padding: 0 5px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-select-selection-item{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
  :where(.css-mzwlov).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    //outline: 0;
  }
  .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    outline: 0;
  }
  .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    outline: 0;
  }
  :where(.css-mzwlov).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    padding: 0 5px;
  }
  :where(.css-mzwlov).ant-select .ant-select-arrow {
    top: 87%;
    inset-inline-end: 17px;
  }
  .ant-select-show-search:where(.css-mzwlov).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__address-warning{
    font-size: 14px;
    color: red;
    font-weight: 400;
  }
  &__delivery-warning{
    color: var(--purple, #7364FF);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

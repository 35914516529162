@import "src/config/theme/core";
.header{
  width: calc(100% - 134px);
  margin: 24px 67px;
  border-radius: 20px;
  background: var(--black, #0F0F11);
  display: flex;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;

  &.open-mobile-menu{
    border-bottom: 1px solid #E7E7E7;
    background: var(--white, #FFF);
  }
  &.fixed {
    position: fixed;
    top: -2px;
    left: 0;
    width: 100%;
    //margin: 0;
    //border-radius: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    //background: var(--white, #FFF);
  }

  &.hidden {
    transform: translateY(-100%); // Скроет меню за пределами экрана
  }
  @media screen and (min-width: 0) and (max-width: 700px){
    width: 100%;
    margin: 0 0 20px;
    border-radius: 0;
    //border: 1px solid red;

    //position: fixed;
    //top: 0;
    //left: 0;
    ////width: 100%;
    ////background: #fff; /* фон меню */
    //box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* тень для меню */
    //transition: transform 0.3s ease-in-out; /* Плавное появление/скрытие */
    //z-index: 1000;
  }
  @media screen and (min-width: 701px) and (max-width: 1024px){
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
  }
  &__links{

    display: flex;
    justify-content: space-between;
    gap: 19px;
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (min-width: 650px) and (max-width: 850px){
      gap: 10px;
    }
    @media screen and (min-width: 0) and (max-width: 650px){
      display: none;
    }

  }
  &__link-mobile{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 40px;
  }
  &__link:hover{
    color: #7364FF;
  }
  &__icons{
    display: flex;
    gap: 24px;
    @media screen and (min-width: 650px) and (max-width: 850px){
      gap: 10px;
    }
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-btn-default {
    box-shadow: none;
    color: transparent;
    transition: none;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
    color: transparent;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-btn:not(:disabled):focus-visible {
    outline: none;
    outline-offset: 0;
    transition: none;
  }
  &__icons > svg{
    cursor: pointer;
    width: 100%;
  }
  &__desktop-icon{
    stroke: white;
  }
  &__modal-btn{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    @media screen and (min-width: 0) and (max-width: 500px){
      width: 100%;
    }
  }
  &__modal-register.modal-v2 .ant-modal-content {
    @media screen and (min-width: 0) and (max-width: 500px){
      padding: 20px;
    }
  }
  &__modal-register.modal-v2 .ant-modal-body {
    @media screen and (min-width: 0) and (max-width: 500px){
      margin-top: 5px;
    }
  }
  &__btn-text{
    margin-top: 10px;
  }
  &__search-icon.blue{
    stroke: #7364FF;
  }
  &__search-icon.white{
    stroke: #fff;
  }
  &__desktop-icon{
    display: block !important;
    @media screen and (min-width: 0) and (max-width: 650px){
      display: none !important;
    }
  }

  &__menu-icon{
    display: none !important;
    @media screen and (min-width: 0) and (max-width: 650px){
      display: block !important;
    }
  }
  &__mobile-bottom-menu{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999999;

    //color: #0F0F11; //for icon

    background: var(--white, #FFF);
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 20px 48px;
    display: none;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    @media screen and (min-width: 0) and (max-width: 650px){
      display: flex;
    }
  }
  &__mobile-links{
    position: absolute;
    top: 102%;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--white, #FFF);
    z-index: 9999;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 150px;
  }
  &__mobile-popup{
    //padding: 24px 16px;
    //overflow-x: auto
  }
  &__mobile-icon{
    width: fit-content;
    stroke: #0F0F11;
    //@include for-phone-big{
    //  stroke: #0F0F11;
    //}
  }
  &__mobile-icon.button-v2.ant-btn{
    width: fit-content;
    box-shadow: none;
  }
  &__back-button{
    color: #8F8F93;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
    padding: 24px 0 0 16px;
  }
  &__logo-mobile{
    width: 172px;
    height: 32px;
  }
}

.tabs{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px 30px;
  flex-direction: column;
  //justify-content: space-between;
  border-radius: 32px;
  background: #F7F7F7;
  @media screen and (min-width: 650px) and (max-width: 1300px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (min-width: 0) and (max-width: 650px){
    //display: none;
    position: absolute;
    z-index: 10000;
    background-color: #fff;
    border-radius: 0;
    height: 80%;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 16px 30px;
    //gap: 15px;
  }
  &__item-box{
    @media screen and (min-width: 650px) and (max-width: 1300px) {
      display: flex;
      flex-direction: row;
    }
    @media screen and (min-width: 0) and (max-width: 650px){
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    margin-bottom: 16px;
    @media screen and (min-width: 0) and (max-width: 650px){
      display: none;
    }
  }
  &__btn.ant-btn{
    border-radius: 12px;
    font-weight: 600;
    justify-content: flex-start;
    padding: 20px 16px;
    @media screen and (min-width: 0) and (max-width: 650px) {
      //background-color: #F7F7F7;
      height: 57px;
      justify-content: center;
    }
  }
  &__btn-logout{
    position: absolute;
    bottom: 32px;
    @media screen and (min-width: 650px) and (max-width: 1300px) {
      bottom: 30px;
    }
    @media screen and (min-width: 0) and (max-width: 650px) {
      display: none;
    }
  }
  &__btn-logout.ant-btn{
    justify-content: flex-start;
    @media screen and (min-width: 650px) and (max-width: 1300px) {
      width: fit-content;
    }
    @media screen and (min-width: 0) and (max-width: 650px) {
      display: none;
    }
  }
  &__mobile-logout{
    display: none;
    @media screen and (min-width: 0) and (max-width: 650px) {
      display: block;
      color: #E90000;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 550;
      line-height: normal;
      text-align: center;
    }
  }
}

//breakpoints
//@breakpoint-laptop-large: 1920px; // by design desktop width
//@breakpoint-laptop-medium: 1600px; //key breakpoint
//@breakpoint-laptop-small: 1200px;
//
//@breakpoint-tablet-last: 1199px; // last breakpoint
//@breakpoint-tablet-large: 1024px; // by design tablet width
//@breakpoint-tablet-medium: 992px;
//@breakpoint-tablet-small: 768px;
//
//@breakpoint-mobile-last: 767px; // last breakpoint
//@breakpoint-mobile-large: 576px; //key breakpoint
//@breakpoint-mobile-medium: 480px;
//@breakpoint-mobile-small: 375px; // by design phone width

$b-laptop-large: 1920px; // by design desktop width
$b-laptop-medium: 1300px; //key breakpoint
//$b-laptop-medium: 1600px; //key breakpoint
$b-laptop-small: 1200px;

$b-tablet-last: 1199px; // last breakpoint
$b-tablet-large: 1024px; // by design tablet width
$b-tablet-medium: 992px;
$b-tablet-small: 768px;

$b-mobile-last: 767px; // last breakpoint
$b-mobile-large: 576px; //key breakpoint
$b-mobile-medium: 480px;
$b-mobile-small: 375px; // by design phone width


///
@mixin for-phone-only {
  @media (max-width: $b-mobile-medium) { @content; }
}
@mixin for-phone-big{
  @media (min-width: $b-mobile-medium) and (max-width: $b-mobile-large) { @content }
}
@mixin for-tablet {
  @media (min-width: $b-tablet-large) and (max-width: $b-laptop-medium){ @content; }
}

@mixin for-tablet-medium {
  @media (min-width: $b-tablet-small) and (max-width: $b-tablet-large){ @content }
}
@mixin for-tablet-small {
  @media (min-width: $b-mobile-large) and (max-width: $b-tablet-small){ @content }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
//// Миксин для медиазапросов с максимальной шириной
//@mixin media($size, $rules) {
//  @media screen and (max-width: $size) {
//    #{$rules};
//  }
//}
//
//// Миксин для медиазапросов с минимальной шириной
//@mixin media-min($size, $rules) {
//  @media screen and (min-width: $size) {
//    #{$rules};
//  }
//}
//
//// FROM 340 TO 767
//@mixin media-from-zero-to-smallest($rules) {
//  @media screen and (min-width: 340px) and (max-width: 767px) {
//    #{$rules};
//  }
//}
//
//// FROM 768 to 1600
//@mixin media-tablet-and-m-laptop($rules) {
//  @media screen and (min-width: #{($breakpoint-tablet-small - 1)}) and (max-width: #{$breakpoint-laptop-medium}) {
//    #{$rules};
//  }
//}
//
//// FROM 0 to 767
//@mixin media-mobile-only($rules) {
//  @media screen and (max-width: #{$breakpoint-tablet-small}) {
//    #{$rules};
//  }
//}
//
//// FROM 768 to 1199
//@mixin media-tablet-only($rules) {
//  @media screen and (min-width: #{$breakpoint-tablet-small}) and (max-width: #{($breakpoint-laptop-small - 1)}) {
//    #{$rules};
//  }
//}
//
//// FROM 1200 to infinity
//@mixin media-laptop-only($rules) {
//  @media screen and (min-width: #{$breakpoint-laptop-small}) {
//    #{$rules}
//  }
//}

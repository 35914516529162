.catalog-parent {
  display: flex;
  flex-direction: column;
  //gap: 32px;
  padding: 8px 67px 32px 67px;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 0 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 4px 30px 78px 30px;
  }
  &__top-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    @media screen and (min-width: 0) and (max-width: 767px) {
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
      //border: 1px solid red;
    }
    //padding: 10px;
    //border-bottom: 1px solid #ccc;
  }
  &__left{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 20px;
    @media screen and (min-width: 0) and (max-width: 767px) {
      justify-content: space-between;
      width: 100%;
    }
  }
  &__category-name{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 24px;
    }
  }
  &__count{
    color: #B0B0B0;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    @media screen and (min-width: 0) and (max-width: 767px) {
      justify-content: space-between;
      width: 100%;
    }
  }
  //&__btn-filters.ant-btn-icon > svg {
  //  @media screen and (min-width: 0) and (max-width: 576px) {
  //    width: 16px !important;
  //    height: 16px !important;
  //  }
  //}
  //&__btn-filters.ant-btn-icon > span > svg {
  //  @media screen and (min-width: 0) and (max-width: 576px) {
  //    width: 16px !important;
  //    height: 16px !important;
  //  }
  //}
  &__btn-filters.ant-btn-text{
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    width: fit-content;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
    }
  }
  &__btn-filters.ant-btn-text:hover{
    color: var(--black, #0F0F11);
  }
  &__sort-select > .ant-row{
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    min-width: 225px;
    max-width: fit-content;
    @media screen and (min-width: 0) and (max-width: 460px) {
      min-width: 120px;
    }
  }
  &__sort-select.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 21px;
    color: #7D7D7D;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
      color: #0F0F11;
    }
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select .ant-select-arrow{
    color: #848484;
  }
  &__sort-select{
    width: fit-content;
    @media screen and (min-width: 0) and (max-width: 767px){
      position: relative;
      left: 8px;
      //min-width: 120px;
    }
  }
  .ant-form-item-label > label {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 615px) {
      display: none;
    }
    //@media screen and (min-width: 0) and (max-width: 576px) {
    //  font-size: 12px;
    //}
  }

  :where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
    border-color: transparent;
    box-shadow: none;
  }
  :where(.css-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    outline: 0;
  }

  .ant-select-focused{
    border-color: transparent;
    box-shadow: none;
  }
  .ant-select-selection-item{
    color: #7D7D7D;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
    }
  }
  .ant-form-item-label > label {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
    }
  }
  &__content {
    display: flex;
    transition: gap 0.5s ease, width 0.5s ease; // Плавный переход для gap и ширины
    //transition-delay: 5.3s;
    &--filters-open {
      gap: 40px;
      @media  screen and (min-width: 768px) and (max-width: 1200px) {
        gap: 30px;
      }
      .catalog-parent__catalog {
        width: calc(100% - 315px);
        @media  screen and (min-width: 768px) and (max-width: 1200px) {
          width: calc(100% - 290px);
        }

      }
    }
  }

  &__slide-filters {
    width: 0;
    transition: width 0.2s ease-in-out, opacity 0.1s ease;
    opacity: 0;
    //background-color: #fff;
    //height: 100vh;
    //767
    //visibility: hidden;

    &--visible {
      width: 315px;
      opacity: 1;

      @media  screen and (min-width: 576px) and (max-width: 1200px) {
        width: 290px;
      }
      @media  screen and (min-width: 0) and (max-width: 576px) {
        width: 100%;
      }
      //576
      //@media  screen and (min-width: 0) and (max-width: 767px) {
      //  width: 290px;
      //  z-index: 1000;
      //  position: absolute;
      //  box-shadow: 11px 11px 20px rgba(243, 243, 243, 0.68); //
      //}
      //visibility: visible;
    }
  }

  &__catalog {
    flex-grow: 1;
    transition: width 0.3s ease;

    &--full-width {
      width: 100%; // Полная ширина при скрытых фильтрах
      @media  screen and (min-width: 0) and (max-width: 767px) {
        //width: 100%;
        transition: none;
        //position: absolute;
      }
    }
    &--part-width{
      @media  screen and (min-width: 576px) and (max-width: 767px) {
        width: 100%;
        transition: none;
        //background-color: rgba(0, 0, 0, 0.05); // Полупрозрачный черный цвет для затемнения
        //backdrop-filter: blur(5px); // Размытие фона
        //filter: brightness(0.9);
        //position: absolute;
      }
      @media  screen and (min-width: 0) and (max-width: 576px) {
        display: none;
        //background-color: rgba(0, 0, 0, 0.05); // Полупрозрачный черный цвет для затемнения
        //backdrop-filter: blur(5px); // Размытие фона
        //filter: brightness(0.9);
        //position: absolute;
      }
    }
  }


  &__more{
    width: 100%;
    display: flex;
    padding: 20px 16px 18px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #F6F6F6;
    color: #999;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 32px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__breadcrumbs{
    display: flex;
    align-items: center;
    //justify-content: center;
    flex-direction: row;
    margin-bottom: 24px;
    gap: 10px;
  }

  &__breadcrumbs-last, &__breadcrumbs-item, &__b-delimiter{
    color: #919191;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  &__b-delimiter{
    color: #E1E1E1;
  }
  &__breadcrumbs-item{
    cursor: pointer;
  }
  &__breadcrumbs-item:hover{
    color: #303030;
  }


  &__catalog-box{
    min-height: 80vh;
  }
  &__catalog-items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 64px;
    column-gap: 25px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }
    &--tablet{
      @media screen and (min-width: 767px) and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        //border: 1px solid red;
      }
      @media screen and (min-width: 576px) and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        //border: 1px solid red;
      }

    }
  }
  &__nothing-found{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-spin-dot-item{
    background-color:#303030 !important;
  }
  &__centred-spin{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

.reviews{
  margin: 64px 67px 64px 67px;
  overflow: hidden;
  @media screen and (min-width: 0) and (max-width: 576px) {
    margin: 16px 16px 30px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    margin: 32px 30px 41px 30px;
  }
  &__top{
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 32px;
    margin-bottom: 32px;
    //border-bottom: 1px solid #E0E0E0;
    @media screen and (min-width: 0) and (max-width: 767px) {
      //font-size: 20px;
      padding-bottom: 24px;
      margin-bottom: 27px;
    }
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    //padding-bottom: 32px;
    //margin-bottom: 32px;
    //border-bottom: 1px solid #E0E0E0;
    @media screen and (min-width: 0) and (max-width: 767px) {
      font-size: 20px;
      //padding-bottom: 24px;
      //margin-bottom: 27px;
    }
  }
  &__slider{
    position: relative;
    //left: 5%;
    height: fit-content;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 100%;
      overflow-x: scroll;
      scrollbar-width: none;
    }
  }
  //.slick-active, .slick-current{
  //  width: 200px;
  //}
  .slick-track{
    display: flex;
    flex-direction: row;
    gap: 28px;
    @media screen and (min-width: 0) and (max-width: 767px) {
      gap: 12px;
    }
  }
  //.slick-list{
  //  position: relative;
  //}
  .slick-next {
    //position: absolute;
    //bottom: -5%;
    //left: 30%;
    position: absolute;
    top: -72px;
    right: 0;
    transform: scaleX(-1);
    stroke: #5546E1;
    cursor: pointer;
    //@media screen and (min-width: 0) and (max-width: 576px) {
    //  //flex-direction: row;
    //}
    //@media screen and (min-width: 576px) and (max-width: 1300px) {
    //  bottom: -15%;
    //}
  }
  .slick-prev{
    //position: absolute;
    //bottom: -5%;
    //left: 25%;
    position: absolute;
    top: -72px;
    right: 52px;
    stroke: #5546E1;
    cursor: pointer;
    //@media screen and (min-width: 0) and (max-width: 576px) {
    //  //flex-direction: row;
    //}
    //@media screen and (min-width: 576px) and (max-width: 1300px) {
    //  bottom: -15%;
    //}
  }
  .slick-next:hover, .slick-prev:hover{
    stroke: #7364FF;
  }
  &__channels{
    margin-top: 32px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    @media screen and (min-width: 0) and (max-width: 767px) {
      font-size: 20px;
    }
  }
  &__link{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-decoration-line: underline;
    @media screen and (min-width: 0) and (max-width: 767px) {
      font-size: 20px;
    }
  }
}

.basket-item{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 32px;
  margin-top: 24px;
  @media screen and (min-width: 0) and (max-width: 576px) {
    margin-right: -16px;
    margin-left: -16px;
    width: calc(100% + 32px);
    //border-radius: 0;
    padding: 28px 16px;
  }
  &__img-container{
    display: flex;
    min-width: 190px;
    max-width: 190px;
    height: 190px;
    padding: 43.672px 11.345px 43.934px 10.207px;
    justify-content: center;
    align-items: center;
    border-radius: 18.149px;
    border: 0.567px solid rgba(115, 100, 255, 0.10);
    background: var(--white, #FFF);
    margin-right: 32px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-right: 8px;
      height: 100px;
      min-width: 120px;
      max-width: 120px;
    }
  }

  &__img{
    width: 100%;
  }
  &__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    width: inherit;
  }
  &__info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__right{
    display: flex;
    flex-direction: row;
  }
  &__count{
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background: #F8F8F8;
    padding: 4px 16px;
    align-items: center;
    //gap: 12px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      padding: 4px 10px;
    }
  }
  &__count-decrement{
    //stroke-width: 1.5px;
    background-color: #757575;
    //color: #303030;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    width: 14.142px;
    height: 2px;
    margin-right: 12px;
    cursor: pointer;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 10px;
      margin-right: 8px;
    }
  }
  &__count-decrement-disabled{
    background-color: rgba(141, 141, 141, 0.09);
    cursor: default;
  }
  &__count-increment{
    color: #8D8D8D;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-left: 12px;
    cursor: pointer;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 15px;
      margin-left: 8px;
    }
  }
  &__count-item{
    color: #303030;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 0 18px;
    border-right: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 10px;
      padding: 0 10px;
    }
  }
  &__checkbox.ant-checkbox-wrapper {
    display: flex !important;
    align-items: flex-start !important;
    height: fit-content;
    margin-right: 16px;
  }
 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0F0F11 !important;
  border-color: #0F0F11 !important;
}
  .ant-checkbox-inner:hover {
    border-color: #0F0F11 !important;
    background-color: #0F0F11 !important;
  }

  :where(.css-dev-only-do-not-override-mzwlov).ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: #0F0F11 !important;
    border-color: #0F0F11 !important;
  }
  .ant-checkbox-checked:hover{
    background-color: #0F0F11 !important;
    border-color: #0F0F11 !important;
  }
  .ant-checkbox{
    align-self: flex-start !important;
  }
  &__name-size{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    gap: 10px;
  }
  &__name{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: uppercase;

    max-width: 330px; /* Установите максимальную ширину для имени товара */
    //white-space: wrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__price{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__sub-size{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__size{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__like{
    margin-right: 12px;
  }
}

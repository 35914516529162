.table{
  &__sizes-grid {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 32px;
    border-radius: 30px;
    border: 1.052px solid #D5D0D0;
    padding: 20px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-bottom: 24px;
    }
  }
  &__tab-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    padding-bottom: 18px;
    border-bottom: 1px solid #E1E1E1;
  }

  &__tab {
    color: #0F0F11;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px 24px 8px 24px;
    border-radius: 32px;
    border: 1px solid #C8C8C8;
    cursor: pointer;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 13px;
    }
    //background: var(--black, #0F0F11);
  }

  &__tab.active, &__tab-info.active {
    color: var(--white, #FFF);
    background: var(--black, #0F0F11);
    border: none;
  }
  &__tab-pane {
    display: none;
  }

  &__tab-content{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 18px;
    column-gap: 18px;
    padding-top: 18px;
  }

  &__tab-item {
    display: flex;
    min-width: 82px;
    padding: 18px 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 12px;
    border: 1px solid #C8C8C8;
    cursor: pointer;
    color: var(--black, #0F0F11);
    @media screen and (min-width: 490px) and (max-width: 1210px) {
      padding: 16px 3px;
    }
    @media screen and (min-width: 0) and (max-width: 550px) {
      padding: 15px 24px;
    }
  }

  &__tab-item.not-available {
    border: none;
    cursor: default;
  }

  &__tab-item.choose {
    color: #fff;
    background: var(--black, #0F0F11);
  }

  &__tab-item-size {
    //color: var(--black, #0F0F11);
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: none;
    text-transform: uppercase;
    margin-bottom: 6px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__tab-item-price {
    white-space: nowrap;
    color: #A8A8A8;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: none;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
    }
  }
}

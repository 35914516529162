.copy-link{
  padding: 8px 0 65px 0;
  &__top{
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
  }
  &__title{
    //width: 100%;
    //max-width: 690px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    padding-bottom: 31px;
    padding-left: 67px;
    //border-bottom: 1px solid #E0E0E0;
    margin: 0 auto;
    //display: flex;
    //align-items: flex-start;

    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 20px;
      padding-left: 16px;
      padding-bottom: 22px;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      padding-left: 30px;
    }
  }
  &__content{
    overflow-x: scroll;
    scrollbar-width: none;
    //width: 100%;
    //max-width: 1400px;
    margin-top: 42px;
    padding: 0 67px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;

    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-top: 24px;
      padding: 0 16px;
    }
    @media screen and (min-width: 576px) and (max-width: 1024px) {
      margin-top: 32px;
      padding: 0 30px;
    }
  }
  &__key{
    display: flex;
    width: 36px;
    padding: 9px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--purple, #7364FF);

    color: var(--white, #FFF);
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15.75px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 18.9px */
  }
  &__item-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    //max-width: 230px;
    margin-top: 14px;
    margin-bottom: 18px;
  }
  &__link{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    text-decoration-line: underline;
    text-decoration-thickness: 0.5px;
  }
  &__photo{
    border-radius: 12px;
    box-shadow: 0 1.812px 9.058px 0 rgba(2, 49, 82, 0.10);
  }
}

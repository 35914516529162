@import "src/config/theme/core";
.special{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 1534px;
  padding: 0 67px;
  margin: 100px auto 160px;
  @include for-tablet-medium {
    padding: 0 30px;
  }
  @include for-tablet-small {
    padding: 0 30px;
    margin: 80px 0 110px;
  }
  @include for-phone-big {
    padding: 0 30px;
    margin: 60px 0 90px;
  }
  @include for-phone-only {
    padding: 0 16px;
    margin: 50px 0 80px;
  }

  &__info{
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    width: 49.3%;
    margin-bottom: 32px;
    @include for-tablet-medium{
      width: 66%;
    }@include for-tablet-small{
      width: 66%;
    }@include for-phone-big{
      width: 100%;
    }@include for-phone-only{
      width: 100%;
    }
  }
  &__content{
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 64px;
    @include for-tablet {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 1fr);
    }  @include for-tablet-medium {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 1fr);
    }  @include for-tablet-small {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 1fr);
    }

    @include for-phone-big{
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(2, 1fr);
    }
    @include for-phone-only{
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__content > *:nth-child(n+9) {
    display: none;
    @include for-tablet {
      display: block;
    } @include for-tablet-medium {
      display: block;
    } @include for-tablet-small {
      display: block;
    }@include for-phone-big {
      display: none;
    }
  }
  &__category-name{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    @include for-phone-big{
      font-size: 21px;
    } @include for-phone-only{
      font-size: 21px;
    }
  }
  &__see-more{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    cursor: pointer;
    @include for-phone-big{
      font-size: 14px;
    } @include for-phone-only{
      font-size: 14px;
    }
  }
  &__see-more:hover{
    color: #3B3DC6;
  }
}

.registration{
  width: 557px !important;
  .ant-modal-content{
    padding: 45px 55px;

    @media screen and (min-width: 400px) and (max-width: 500px) {
      padding: 30px 15px;
    }
    @media screen and (min-width: 0) and (max-width: 400px) {
      padding: 20px 10px;
    }
  }
  .ant-modal-body{
    margin-top: 35px;
  }
  &__form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //height: 447px;
    width: 100%;
  }
  .ant-form-item {
    height: 85px;
  }
  &__notice{
    text-align: left;
    color: var(--black, #0F0F11);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    //width: 100%;
    margin: 12px 0 24px 0;
  }
  &__notice-link{
    color: #8C8C8C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
  }
  &__modal-btn{
    width: 100%;
  }
  &__btn{
    border-radius: 12px !important;
  }
  &__btn-tg{
    margin: 12px 0;
  }
  &__btn-tg.button-v2.ant-btn-primary-light{
    @media screen and (min-width: 0) and (max-width: 450px) {
      font-size: 15px;
    }
  }
  &__btn-tg.button-v2.ant-btn-primary-green {
    @media screen and (min-width: 0) and (max-width: 450px) {
      font-size: 15px;
    }
  }
  &__btn-account{
    justify-content: left !important;
    //text-align: left !important;
  }
  &__code{
    margin-top: 20px;
  }
  //&__btn-account{
  //  justify-content: left;
  //}
  //&__btn-tg{
  //  //border-radius: 12px;
  //  background: #29A1DD !important;
  //}
  //&__btn-tg:hover{
  //  //border-radius: 12px;
  //  background: #29A1DD !important;
  //}
}

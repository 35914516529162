.modal-v2 {

  &__modal-header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: var(--black, #0F0F11);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */

    font-family: 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
  }

  &__close-btn-icon {
    font-size: 24px !important;
    color: #C1C1C1 !important;
    transform: rotate(45deg);
    &.referral{
      display: none;
    }
  }

  .ant-modal {
    border-radius: 32px !important;
    &-content {
      padding: 32px;
      //border-right: 32px;
    }
    &-body {
      //margin: 24px 0;
      color: var(--black, #0F0F11);
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
    }

    &-header {
      margin: 0!important;
      //padding: 0 0 16px;
    }


    &-body {
      padding: 0;
      margin-top: 22px;
    }

    &-footer {
      margin-top: 20px;
      //padding: 24px 0 0;
    }
  }
}

// prevent body width change
// from opened ant modal
body.ant-scrolling-effect {
  width: 100% !important;
}

.profile{
  display: flex;
  gap: 22px;
  padding: 8px 67px 82px 67px;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 80px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 4px 30px 50px 30px;
  }
  @media screen and (min-width: 0) and (max-width: 1300px) {
    flex-direction: column;
  }
  //height: 100vh;
  &__menu{
    width: 331px;
    @media screen and (min-width: 650px) and (max-width: 1300px) {
      width: 100%;
    }
    @media screen and (min-width: 0) and (max-width: 650px){
      display: none;
    }
  }
  &__content{
    flex-grow: 1;
  }
  &__loyalty{
    //height: 361px;
    border-radius: 32px;
    border: 1px solid rgba(123, 42, 229, 0.20);
    text-align: center;
    overflow-y: hidden;
    scrollbar-width: none;
    @media screen and (min-width: 0) and (max-width: 576px) {
      border: none;
      border-radius: 0;
    }
  }
  &__top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 23px 32px;
    border-bottom: 1px solid #E5D4FA;
    @media screen and (min-width: 576px) and (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-bottom: 0;
      padding: 0;
    }
  }
  &__status-points{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 70px;
    @media screen and (min-width: 0) and (max-width: 800px) {
      gap: 30px;
    }
  }
  &__s-title, &__p-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__s-title.status{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  &__s-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__s-type, &__p-count{
    color: #7B2AE5;
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 438px){
      font-size: 25px;
    }
  }
  &__rules{
    text-align: right;
    color: #BEAAD9;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (min-width: 576px) and (max-width: 800px) {
      display: flex;
      flex-direction: row-reverse;
      gap: 15px;
      border-radius: 12px;
      background: #7B2AE5;
      color: white;
      padding: 16px;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 8px;
      border-radius: 12px;
      background: #7B2AE5;
      color: white;
      padding: 14px 16px;
    }
  }
  &__r-title:nth-child(1){
    padding-bottom: 11px;
    @media screen and (min-width: 0) and (max-width: 800px) {
      padding-bottom: 0;
    }
  }
  &__bottom{
    margin: 32px 32px 28px 32px;
    overflow-y: scroll;
    scrollbar-width: none;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin: 0;
    }
  }

  &__progress-bar-container{
    position: relative;
    left: 0;
    min-width: 973px;
    max-width: 100%;
    height: 50px;
    background-color: transparent;
    margin: 30px 5px;

    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
  &__progress-bar {
    position: relative;
    width: 100%;
    height: 3px;
    background-color: #F0EEFF;

    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  &__status {
    position: absolute;
    top: -28px;
    transform: translateX(-50%);
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__status:nth-child(1){
    left: 3% !important;
  }
  &__status:nth-child(2){
    left: 13% !important;
  }
  &__status:nth-child(3){
    left: 35.3333% !important;
  }
  &__status:nth-child(4){
    left: 69.6667% !important;
  }

  &__status-count{
    color: #DDD9FF;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
  }
  &__status-count.active{
    color: #7B2AE5;
  }
  &__status-count:nth-child(1){
    margin-left: 2px;
  }
  &__status-circle.active {
    width: 16px;
    height: 16px;
    background: #7B2AE5;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__status-circle{
    width: 16px;
    height: 16px;
    border: 3px solid #F0EEFF;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__status-label {
    color: #DADADC;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    margin-top: 8px;
  }

  &__status-label.active{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
  }

  &__progress-line {
    position: absolute;
    top: 50%;
    left: 0;
    height: 4px;
    background: #7B2AE5;
    border-radius: 3px;
    transform: translateY(-50%);
    transition: width 0.3s;
  }

  &__benefits{
    display: grid;
    width: 100%;
    min-width: 850px;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media screen and (min-width: 0) and (max-width: 1100px) {
      gap: 12px;
    }

  }
  &__b-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-radius: 32px;
    background: #FAF6FF;
    padding: 16px 18px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 290px;
    }
  }
  &__text{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    text-align: left;
  }
  &__circle-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 57px;
    height: 57px;
    background-color: #fff;
    border-radius: 50%;
  }
  &__personal-link{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 32px;
    border: 1px solid rgba(123, 42, 229, 0.20);
    margin-top: 20px;
    margin-bottom: 52px;
    padding: 32px;
    @media screen and (min-width: 576px) and (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border: none;
      padding: 20px 0;
      margin-bottom: 40px;
    }
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__subtitle{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
  }
  &__right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
  }
  &__link{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__form{
    display: grid;
    grid-template-columns: 53% auto auto;
    column-gap: 20px;
    row-gap: 70px;
    @media screen and (min-width: 567px) and (max-width: 850px) {
      grid-template-columns: 50% 50%;
    }
    @media screen and (min-width: 0) and (max-width: 567px) {
      grid-template-columns: 100%;
      row-gap: 30px;
    }
    //row-gap: 70px;
  }
  &__inputs{
    width: 100%;
    color: #0F0F11;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: #F8F8F8;

    display: flex;
    height: 55px;
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
    //margin-top: 7px;
    border-radius: 8px;

    &.warning{
      border: 0.5px solid #ff4d4f;
    }
  }
  &__address-warning{
    font-size: 14px;
    color: red;
    font-weight: 400;
  }
  &__inputs::placeholder{
    color: #0F0F11;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__inputs.ant-input{
    color: #0F0F11;
  }
  .input-big {
    grid-column: span 1; /* Первый инпут занимает половину ширины */
  }

  .input-small {
    grid-column: span 1; /* Остальные инпуты занимают оставшееся пространство */
  }
  .select .ant-select-selector {
    max-width: 225px; /* Установите нужное значение */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__save-info.ant-btn{
    width: fit-content;
  }
  &__save-info.button-v2.ant-btn-text {
    @media screen and (min-width: 0) and (max-width: 567px) {
      font-size: 12px;
    }
  }
  &__user-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
  &__user-info-block{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      gap: 12px;
    }
  }
  &__user-avatar{
    border-radius: 50%;
  }
  &__user-name{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 16px;
      margin-bottom: 6px;
    }
  }
  &__user-mail{
    color: #A7A7A7;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__address-label{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5;
  }
  &__input-select{
    margin-top: 2px;
  }
  &__input-select.ant-select-single.ant-select-sm{
    height: 54px;
    padding: 0 9px;
    border-radius: 8px;
    background: #F8F8F8;
  }
  &__input-select.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border-radius: 8px;
    border-color: transparent;
    background: #F8F8F8;
  }
  &__input-select.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
    border-color: transparent;
    border-radius: 8px;
    background: #F8F8F8;
  }
 .ant-select-selection-search-input {
  height: 100%;
 }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: #F8F8F8;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    border-radius: 8px;
    height: 55px;
    padding: 0 16px;
    //max-width: 205px; /* Установите нужное значение */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single {
    font-size: 14px;
    height: 55px;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: none;
  }
  .ant-select-show-search:where(.css-dev-only-do-not-override-mzwlov).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    padding: 0 5px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-select-selection-item{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
  :where(.css-mzwlov).ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: none;
    box-shadow: none;
    //outline: 0;
  }
  .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: none;
    box-shadow: none;
    outline: 0;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, :where(.css-dev-only-do-not-override-mzwlov).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    width: 100px;
    height: 100px;
    text-align: center;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 0.3s;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 80px;
      height: 80px;
    }
  }
  :where(.css-mzwlov).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, :where(.css-mzwlov).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    width: 100px;
    height: 100px;
    text-align: center;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 0.3s;
    @media screen and (min-width: 0) and (max-width: 576px) {
      width: 80px;
      height: 80px;
    }
  }
  :where(.css-mzwlov).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    padding: 0 5px;
  }
  :where(.css-mzwlov).ant-select .ant-select-arrow {
    top: 87%;
    inset-inline-end: 17px;
  }
  .ant-select-show-search:where(.css-mzwlov).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :where(.css-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: #F8F8F8;
    border-radius: 8px;
  }
  :where(.css-mzwlov).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    //height: 100%;
    padding: 0 16px;
    height: 55px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: transparent;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 18px;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    outline: 0;
  }
  &__tooltip{
    position: relative;
    display: inline-flex;
    cursor: pointer;
  }
  &__tooltip-info{
    visibility: hidden;
    width: 306px;
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 50%;
    opacity: 0;
    transition: opacity 0.3s;

    display: flex;
    padding: 28px 32px;
    flex-direction: row;
    align-items: flex-start;
    gap: 48px;

    border-radius: 32px;
    background: var(--white, #FFF);
    box-shadow: 0 2px 15px 0 rgba(115, 100, 255, 0.15);
  }
  &__t-left, &__t-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__t-title{
    color: #ACACAC;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__t-item{
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__t-cash{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__tooltip:hover &__tooltip-info {
    visibility: visible;
    opacity: 1;
  }
}

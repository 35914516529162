.order-full-info{
  //height: fit-content;
  display: flex;
  gap: 22px;
  padding: 8px 67px 82px 67px;
  height: fit-content;
  min-height: 70vh;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 30px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 4px 30px 50px 30px;
  }
  @media screen and (min-width: 0) and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }
  &__menu{
    width: 331px;
    @media screen and (min-width: 650px) and (max-width: 1300px) {
      width: 100%;
    }
    @media screen and (min-width: 0) and (max-width: 650px){
      display: none;
    }
  }
  &__box{
    flex-grow: 1;
  }
  &__top{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 32px;
    //background: #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    @media screen and (min-width: 0) and (max-width: 1300px) {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  &__btn-back.ant-btn{
    width: fit-content;
    color: #898989;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  &__top-info{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    @media screen and (min-width: 0) and (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }
  &__order-id{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
  }
  &__status{
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    border-radius: 32px;
    display: flex;
    padding: 2px 16px 3px 16px;
    align-items: center;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
      padding: 2px 16px 3px 16px;
    }
  }
  &__list-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 24px;
    border-bottom: 1px solid #E6E6E6;
  }
  &__d-title, &__u-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
  }
  &__d-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  &__express{
    margin-bottom: 28px;
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    border-radius: 32px;
    background: var(--purple, #7364FF);
    display: flex;
    padding: 2px 16px 3px 16px;
    align-items: center;
  }
  &__d-type{
    display: flex;
    width: 119px;
    padding: 16px 32px 14px 32px;
    justify-content: center;
    align-items: center;
    background: var(--purple, #7364FF);
    border-radius: 8px;

    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    margin-bottom: 14px;
    //gap: 10px;
  }
  &__delivery-box{
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid rgba(123, 42, 229, 0.20);
    @media screen and (min-width: 0) and (max-width: 576px) {
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px);
      border-radius: 0;
      border-right: 0;
      border-left: 0;
      padding: 32px 16px 32px 16px;
    }
    //gap: 10px;
    //align-self: stretch;
  }

  &__content{
    display: grid;
    grid-template-columns: 1fr 335px;
    gap: 20px;
    margin-top: 42px;
    @media screen and (min-width: 0) and (max-width: 1300px) {
      margin-top: 35px;
    }
    @media screen and (min-width: 0) and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__left{
    @media screen and (min-width: 0) and (max-width: 750px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &__forms{
    @media screen and (min-width: 750px) and (max-width: 1024px) {
      display: grid;
      grid-template-columns: 49% 49%;
      gap: 20px;
      margin-bottom: 40px;
    }
    @media screen and (min-width: 576px) and (max-width: 750px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 30px;
    }
    @media screen and (min-width: 0) and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-bottom: 30px;
    }
  }
  &__total{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
    //border-bottom: 1px solid #D2CDFF;
  }
  &__count-price{
    padding: 22px 20px;
    height: fit-content;
    border-radius: 12px;
    background: rgba(115, 100, 255, 0.05);
    @media screen and (min-width: 750px) and (max-width: 1024px) {
      width: 50%;
    }
    @media screen and (min-width: 0) and (max-width: 750px) {
      width: 100%;
    }
  }
  &__total-count{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__total-split{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-top: 1px solid #D2CDFF;
    padding-top: 19px;
  }
  &__total-price{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__sum{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #D2CDFF;
  }
  &__s-title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__s-count{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  &__user-info{
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid rgba(123, 42, 229, 0.20);
    margin-top: 32px;
    margin-bottom: 32px;
    @media screen and (min-width: 0) and (max-width: 1024px) {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media screen and (min-width: 0) and (max-width: 577px) {
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px);
      border-radius: 0;
      border-right: 0;
      border-left: 0;
      padding: 32px 16px 32px 16px;
    }
  }
  &__user-input.ant-input::placeholder{
    color: #0F0F11;
  }
  &__input-delivery.ant-input::placeholder{
    color: #0F0F11;
  }
  &__pick-up-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  &__copy-btn{
    margin-top: 35px;
  }
  &__copy-btn.button-v2.ant-btn{
    width: fit-content;
  }
  .ant-form-item {
    height: 100px;
  }
  .ant-form-item {
    height: 100px;
  }
  &__benefit, &__points{
    //width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

  }
  &__benefit{
    margin-top: 20px;
    margin-bottom: 13px;
  }
  &__b-title{
    font-weight: 500;
  }
  &__b-count{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  &__points{
    padding-bottom: 13px;
    //border-bottom: 1px solid #D2CDFF;
  }
  &__p-title, &__p-count{
    font-weight: 500;
  }
  &__p-title{
    padding-left: 10px;
  }
  &__pay{
    margin-top: 7px;
    margin-bottom: 19px;
  }
  &__spin{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 60vh;
  }
}

.order-item{
  width: 100%;
  display: flex;
  padding: 32px 0;
  justify-content: space-between;
  border-bottom: 1px solid #DFDFDF;
  &__top{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 14px;
    @media screen and (min-width: 0) and (max-width: 540px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
      gap: 0;
    }
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    //margin-right: 10px;
    @media screen and (min-width: 0) and (max-width: 540px) {
      font-size: 16px;
    }
  }
  &__status{
    width: fit-content;
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    border-radius: 32px;
    display: flex;
    padding: 2px 16px 3px 16px;
    align-items: center;
    @media screen and (min-width: 0) and (max-width: 540px) {
      display: none;
    }
  }
  &__status-mobile{
    width: fit-content;
    color: var(--white, #FFF);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    border-radius: 32px;
    display: none;
    padding: 2px 8px 3px 8px;
    align-items: center;
    @media screen and (min-width: 0) and (max-width: 540px) {
      display: flex;
      margin-top: 13px;
    }
  }
  &__id{
    color: var(--purple, #7364FF);
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    //margin-left: 10px;
    @media screen and (min-width: 0) and (max-width: 540px) {
      font-size: 16px;
    }
  }
  &__data{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 540px) {
      font-size: 14px;
    }
  }
  &__right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__price{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 14px;
    @media screen and (min-width: 0) and (max-width: 540px) {
      font-size: 16px;
    }
  }
  &__btns{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
  }
  &__to-order{
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
  }
  &__to-order.button-v2.ant-btn-text{
    color: #0F0F11;
  }
  &__review.button-v2.ant-btn-text{
    color: #5441FF;
    text-decoration: underline;
    @media screen and (min-width: 0) and (max-width: 540px) {
      display: none;
    }
  }
  &__review-mobile{
    display: none;

  }
  &__review-mobile.button-v2.ant-btn-text{
    display: none;
    color: #5441FF;
    @media screen and (min-width: 0) and (max-width: 540px) {
      display: block;
      text-decoration: underline !important;
      width: fit-content;
      margin-top: 13px;
    }
  }

  :where(.css-dev-only-do-not-override-mzwlov).ant-btn:not(.ant-btn-icon-only)>.ant-btn-icon:not(:last-child) {
    margin-inline-end: 0;
  }
}

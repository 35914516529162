@import "src/config/theme/core";
.scroll-to-top-button {
  position: sticky;
  bottom: 5%;
  left: 87.6%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000000;
  width: 120px;
  height: 120px;
  @media screen and (min-width: 0) and (max-width: 576px) {
    margin: 0 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    margin: 0 30px;
  }
  @media screen and (min-width: 0) and (max-width: 650px){
    bottom: 10%;
  }
  @media screen and (min-width: 0) and (max-width: 800px){
    width: 80px;
    height: 80px;
  }
}

.scroll-to-top-button.show {
  opacity: 1;
}

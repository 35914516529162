.favorites{
  display: grid;
  grid-template-columns: 331px auto;
  gap: 30px;
  padding: 8px 67px 82px 67px;
  min-height: 70vh;
  height: fit-content;
  @media screen and (min-width: 0) and (max-width: 576px) {
    padding: 0 16px 30px 16px;
  }
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 4px 30px 50px 30px;
  }
  @media screen and (min-width: 0) and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }
  &__menu{
    @media screen and (min-width: 0) and (max-width: 650px){
      display: none;
    }
  }
  &__top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
  }
  &__title{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    @media screen and (min-width: 0) and (max-width: 615px) {
      font-size: 22px;
    }
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 52px;
    column-gap: 20px;
    &--loading-box{
      width: 100%;
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (min-width: 0) and (max-width: 615px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__none{
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    white-space: nowrap;
  }
  &__see-more{
    width: 100%;
    display: flex;
    padding: 20px 16px 18px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #F6F6F6;
    color: #999;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 32px;
  }
  &__sort-select{
    width: fit-content;
  }
  &__sort-select > .ant-row{
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    min-width: 225px;
    max-width: fit-content;
    @media screen and (min-width: 0) and (max-width: 615px) {
      min-width: 120px;
    }
  }
  &__sort-select.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 21px;
    color: #7D7D7D;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 576px) {
      font-size: 12px;
      color: #0F0F11;
    }
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select .ant-select-arrow{
    color: #848484;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
    border-color: transparent;
    box-shadow: none;
  }
  :where(.css-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    outline: 0;
  }

  .ant-select-focused{
    border-color: transparent;
    box-shadow: none;
  }
  :where(.css-mzwlov).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    background: transparent;
  }
  .ant-select-selection-item{
    color: #7D7D7D;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
  }
  .ant-form-item-label > label {
    color: var(--black, #0F0F11);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    @media screen and (min-width: 0) and (max-width: 615px) {
      display: none;
    }
  }
  &__sort-select.ant-select-selection-search-input.ant-select-selection-placeholder {
    color: #7D7D7D;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
